<template>
  <Modal :open="isOpen">
    <template
      slot="content"
    >
      <div
        class="emd-container _highlight_header _footer_end _footer_spaced_mobile"
        v-click-outside="hideMerchantModal"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('createMerchant') }}
          </h2>
          <button
            class="emd-btn _type_link emd-text"
            @click="hideMerchantModal"
          >
            <i class="uil uil-times" />
          </button>
        </div>
        <form
          @submit.prevent="checkForm"
          class="emd-flex-grow_1 emd-flex-shrink_1 emd-flex emd-overflow_hidden"
        >
          <div
            class="emd-container__content emd-flex emd-justify_space-between emd-overflow_hidden"
          >
            <template v-if="activeStep.merchant">
              <emd-alert
                v-if="merchantStepErrors.length"
                :variant="'danger'"
                @cancel="merchantStepErrors = []"
              >
                <template slot="content">
                  <p
                    v-for="(error, index) in merchantStepErrors"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </template>
              </emd-alert>
              <div class="eu-mt_4 emd-flex-grow_1 emd-overflow-y_auto">
                <div class="emd-form-group">
                  <label class="emd-label">{{ $t('labelMerchantName') }}</label>
                  <input
                    class="emd-input"
                    type="text"
                    :class="{ _state_error: merchantFields.name.hasError }"
                    v-model="merchantFields.name.value"
                  />
                </div>
                <div class="emd-form-group">
                  <label class="emd-label">{{ $t('labelAccountName') }}</label>
                  <input
                    class="emd-input"
                    type="text"
                    :class="{
                      _state_error: merchantFields.account_name.hasError
                    }"
                    v-model="merchantFields.account_name.value"
                  />
                </div>
                <div class="emd-form-group">
                  <label class="emd-label">{{ $t('labelClientId') }}</label>
                  <input
                    class="emd-input"
                    type="text"
                    :class="{ _state_error: merchantFields.client_id.hasError }"
                    v-model="merchantFields.client_id.value"
                  />
                  <p class="emd-text _color_subtle _size_tiny">
                    {{ $t('clientIdDescription') }}
                  </p>
                </div>
                <hr class="emd-divider eu-my_3" />
                <div class="emd-form-group">
                  <label class="emd-label">{{
                    $t('labelEmployeePassword')
                  }}</label>
                  <div class="emd-input-group">
                    <input
                      class="emd-input"
                      :class="{
                        _state_error: merchantFields.password.hasError
                      }"
                      :type="merchantInputType"
                      autocomplete="current-password"
                      v-model="merchantFields.password.value"
                    />
                    <a
                      v-on:click="
                        toggleInput('merchantInputType', merchantInputType)
                      "
                      class="emd-input-icon__link"
                    >
                      <i
                        class="uil uil-eye"
                        v-if="merchantInputType === 'password'"
                      />
                      <i
                        class="uil uil-eye-slash"
                        v-if="merchantInputType === 'text'"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="activeStep.done">
              <div class="eu-align_center eu-py_2">
                <div class="el-chunk">
                  <div class="el-chunk">
                    <h3 class="emd-text _size_subpage _color_default">
                      {{ $t('success.title') }}
                    </h3>
                  </div>
                  <p class="emd-text _color_subtle">
                    {{ $t('success.subtitle') }}
                  </p>
                </div>
              </div>
              <div class="emd-panel">
                <div class="emd-panel__section">
                  <div class="emd-panel__block">
                    <div class="el-chunk">
                      <p class="emd-text _type_caption">
                        {{ $t('success.information') }}
                      </p>
                    </div>
                  </div>
                  <div class="emd-panel__block">
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.merchantName') }}
                      </h4>
                      <p>{{ merchantResponseData.name }}</p>
                    </div>
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.merchantId') }}
                        <span
                          class="emd-text _type_label _color_subtle _size_tiny"
                        >
                          {{ $t('success.merchant') }}
                        </span>
                      </h4>
                      <p>{{ merchantResponseData.id }}</p>
                    </div>
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.accountName') }}
                      </h4>
                      <p>{{ merchantResponseData.account_name }}</p>
                    </div>
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.clientId') }}
                      </h4>
                      <p>{{ merchantResponseData.client_id }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emd-panel">
                <div class="emd-panel__section">
                  <div class="emd-panel__block">
                    <!-- <div class="el-chunk">
                      <p class="emd-text _type_caption"> {{$t('success.actions')}} </p>
                    </div> -->
                  </div>
                  <div class="emd-panel__block">
                    <ul class="emd-list _inline _space_base">
                      <li>
                        <a
                          :href="urlCompany"
                          target="_blank"
                          :title="$t('success.btnCompany')"
                          class="emd-btn _color_primary"
                        >
                          {{ $t('success.btnCompany') }}
                        </a>
                      </li>
                      <li>
                        <a
                          :href="urlDash"
                          target="_blank"
                          :title="$t('success.btnDash')"
                          class="emd-btn _color_primary"
                        >
                          {{ $t('success.btnDash') }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            class="emd-container__footer eu-align_right"
            v-if="!activeStep.done"
          >
            <button
              class="emd-btn _color_primary _type_text eu-mr_2"
              type="button"
              @click="hideMerchantModal"
              v-if="activeStep.merchant"
              :disabled="merchantFormIsLoading"
            >
              {{ $t('cancel') }}
            </button>
            <button
              v-if="activeStep.merchant"
              class="emd-btn _color_primary"
              type="submit"
              :class="{ _disabled: merchantFormIsLoading }"
              :disabled="merchantFormIsLoading"
            >
              {{ $t('confirm') }}
            </button>
          </div>
        </form>
      </div>
    </template>
  </Modal>
</template>

<i18n>
{
  "pt-BR": {
    "createMerchant": "Criar Empresa",
    "progress": {
      "merchData" : "Dados da<br>empresa",
      "inviteSent" : "Convite<br>enviado"
    },
    "cancel": "Cancelar",
    "confirm": "Criar",
    "skip": "Pular",
    "labelMerchantName": "Nome da empresa",
    "labelAccountName": "Nome da conta",
    "labelClientId": "Client ID",
    "clientIdDescription": "O campo ClientId representa o Customer Key na Mundipagg e deve ser um GUID válido.",
    "labelEmployeePassword": "confirmação de senha",
    "success": {
      "title": "Empresa criada com sucesso!",
      "subtitle": "Confira abaixo os dados cadastrados:",
      "information": "Informações",
      "merchantName": "Nome da Empresa",
      "merchantId": "ID da Empresa",
      "merchant": "(Empresa)",
      "accountName": "Nome da Conta",
      "clientId": "Client ID",
      "actions": "Ações",
      "btnCompany": "Abrir no Company",
      "btnDash": "Abrir no Dash"
    },
    "errorDefaultMerchant": "Erro ao criar Empresa."
  },
  "en-US": {
    "createMerchant": "Create Merchant",
    "progress": {
      "merchData" : "Merchant<br>data",
      "inviteSent" : "Invite<br>sent"
    },
    "cancel": "Cancel",
    "confirm": "Create",
    "skip": "Skip",
    "labelMerchantName": "Company name",
    "labelAccountName": "Account name",
    "labelClientId": "Client ID",
    "clientIdDescription": "The ClientId field represents the Customer Key in Mundipagg and must be a valid GUID.",
    "labelEmployeePassword": "Password confirmation",
    "success": {
      "title": "Company successfully created!",
      "subtitle": "See summary below:",
      "information": "Information",
      "merchantName": "Merchant name",
      "merchantId": "Merchant's ID",
      "merchant": "(Company)",
      "accountName": "Account name",
      "clientId": "Client ID",
      "actions": "Actions",
      "btnCompany": "Open on Company",
      "btnDash": "Open on Dash"
    },
    "errorDefaultMerchant": "Error creating Company."
  }
}
</i18n>

<script>
import Modal from '@/components/project/globals/Modal.vue'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'

export default {
  name: 'ModalCreateMerchant',
  components: {
    Modal,
    EmdAlert
  },
  data () {
    return {
      merchantInputType: 'password',
      merchantFields: {
        name: {
          required: true,
          hasError: false,
          value: null
        },
        account_name: {
          required: true,
          hasError: false,
          value: null
        },
        client_id: {
          required: true,
          hasError: false,
          value: null
        },
        password: {
          required: true,
          hasError: false,
          value: null
        }
      },
      activeStep: {
        merchant: true,
        done: false
      },
      merchantStepErrors: [],
      merchantFormIsLoading: false,
      merchantResponseData: null
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.merchantResponseData.id}`
    },
    urlDash () {
      return `${process.env.VUE_APP_DASH_URL}/${this.merchantResponseData.id}`
    }
  },
  methods: {
    toggleInput: function (type, value) {
      this[type] = value === 'text' ? 'password' : 'text'
    },
    hideMerchantModal () {
      if (this.isOpen) {
        const keysMerch = Object.keys(this.merchantFields)
        keysMerch.forEach(key => {
          this.merchantFields[key].hasError = false
          this.merchantFields[key].value = null
        })
        this.activeStep.done = false
        this.activeStep.merchant = true
        this.$emit('closeModal')
      }
    },
    checkForm () {
      if (this.isOpen && this.activeStep.merchant) {
        this.createMerchant()
      }
    },
    createMerchant () {
      const keys = Object.keys(this.merchantFields)
      var error = false
      keys.forEach(key => {
        this.merchantFields[key].hasError = this.merchantFields[key].value
          ? this.merchantFields[key].value.isEmpty()
          : true
        if (key === 'email') {
          this.merchantFields[key].hasError = this.merchantFields[key].value
            ? !this.merchantFields.email.value.validEmail()
            : true
        }
        error = this.merchantFields[key].hasError || error
      })
      if (!error) {
        const params = {
          name: this.merchantFields.name.value,
          account_name: this.merchantFields.account_name.value,
          client_id: this.merchantFields.client_id.value,
          password: window.btoa(this.merchantFields.password.value),
          affiliate: false,
          create_accounts: true
        }
        this.merchantFormIsLoading = true
        this.merchantStepErrors = []
        const defaultError = this.$t('errorDefaultMerchant')
        this.$store
          .dispatch('CREATE_MERCHANT', params)
          .then(response => {
            this.merchantResponseData = response
            this.merchantFormIsLoading = false
            this.merchantStepErrors = []
            this.activeStep.merchant = false
            this.activeStep.done = true
          })
          .catch(error => {
            this.merchantStepErrors = []
            this.merchantStepErrors =
              error.body && error.body.errors
                ? error.body.errors.map(item => item.message)
                : [defaultError]
            this.merchantFormIsLoading = false
          })
      }
    }
  }
}
</script>
