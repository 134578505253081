import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import VueCookies from 'vue-cookies'
import extendeds from './utils/extend-string.js'
import getGoogleAnalyticsSettings from './utils/googleAnalytics'

import 'emerald-workbench/dist/emerald.pagarme.min.css'

import './plugins/click-outside'

Vue.config.productionTip = false

if (['production'].includes(process.env.NODE_ENV)) {
  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  Vue.use(VueGtag, googleAnalyticsSettings, router)
}

Vue.use(VueCookies)
new Vue({
  router,
  extendeds,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
