<template>
  <Modal
    :open="isOpenned"
    class="_size_small"
  >
    <template slot="content">
      <div
        class="emd-container _highlight_header _footer_end _footer_spaced_mobile"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('title') }}
          </h2>
          <button
            class="emd-btn _type_link"
            v-on:click="closeModal()"
          >
            <i class="uil uil-times" />
          </button>
        </div>
        <form
          @submit="confirmPassword"
          name="confirmPassword"
          novalidate="true"
        >
          <fieldset>
            <div class="emd-container__content">
              <emd-alert
                :variant="formError ? 'danger' : 'success'"
                v-if="showAlert"
                @cancel="showAlert = false"
                :title="formError ? $t('errorTitle') : $t('successTitle')"
              >
                <template slot="content">
                  <div v-if="formError">
                    <p
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                  <p v-else>{{ $t('account.settings.saved-success') }}</p>
                </template>
              </emd-alert>

              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label _required"
                    for="password"
                    >{{ $t('label') }}</label
                  >
                  <div class="emd-input-group">
                    <input
                      class="emd-input fs-hide"
                      :type="inputType"
                      v-model="password"
                      id="password"
                      autofocus
                      autocomplete="current-password"
                    />
                    <a
                      v-on:click="toggleInput()"
                      class="emd-input-icon__link"
                    >
                      <i
                        class="uil uil-eye"
                        v-if="inputType === 'password'"
                      />
                      <i
                        class="uil uil-eye-slash"
                        v-if="inputType === 'text'"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="emd-container__footer">
              <button
                class="emd-btn _color_primary _type_text"
                type="button"
                v-on:click="closeModal()"
                :disabled="loadingPassword"
              >
                {{ $t('cancelButton') }}
              </button>
              <button
                class="emd-btn _color_primary"
                type="submit"
                :disabled="!password || loadingPassword"
              >
                <span v-if="!loadingPassword">{{ $t('confirmButton') }}</span>
                <emd-loader
                  v-if="loadingPassword"
                  :type="''"
                ></emd-loader>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </template>
  </Modal>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Confirmar senha",
    "label": "Senha employee",
    "cancelButton": "Cancelar",
    "confirmButton": "Confirmar",
    "errorTitle": "Erro",
    "successTitle": "Sucesso",
    "defaultError": "Ocorreu um erro interno. Tente novamente mais tarde"
  },
  "en-US": {
    "title": "Confirm password",
    "label": "Employee password",
    "cancelButton": "Cancel",
    "confirmButton": "Confirm",
    "errorTitle": "Error",
    "successTitle": "Success",
    "defaultError": "There was an internal error. Try again later."
  }
}
</i18n>

<script>
import Modal from '@/components/project/globals/Modal.vue'
import EmdLoader from '@/components/emerald/atoms/EmdLoader.vue'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'

export default {
  name: 'ModalPassword',
  components: {
    Modal,
    EmdLoader,
    EmdAlert
  },
  props: ['callback'],
  data () {
    return {
      isOpenned: true,
      password: null,
      inputType: 'password',
      secretKey: null,
      errors: [],
      loadingPassword: false,
      debounceTimer: undefined,
      showAlert: false,
      formError: false
    }
  },
  methods: {
    openModal: function () {
      this.isOpenned = true
    },
    closeModal: function () {
      this.$emit('cancel')
    },
    toggleInput: function () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    },
    confirmPassword: function (e) {
      e.preventDefault()
      this.loadingPassword = true
      this.showAlert = false
      const password = window.btoa(this.password)
      this.callback(password)
        .then(() => {
          this.loadingPassword = false
          this.formError = false
          this.showAlert = true
          this.closeModal()
        })
        .catch(err => {
          this.formError = true
          this.showAlert = true
          this.loadingPassword = false
          if (err.body && Array.isArray(err.body.errors)) {
            err.body.errors.forEach(error => {
              if (!this.errors.includes(error.message)) {
                this.errors.push(error.message)
              }
            })
          } else {
            this.errors.push(this.$t('defaultError'))
          }
          this.loadingPassword = false
          clearTimeout(this.debounceTimer)
          this.debounceTimer = setTimeout(() => {
            this.errors = []
            this.typeError = null
          }, 7000)
        })
    },
    closeAlert: function () {
      this.errors = []
    }
  }
}
</script>
