<template>
  <div class="emd-flex-grow_1 emd-flex-shrink_1 emd-flex emd-overflow_hidden">
    <div
      class="emd-container__content emd-flex emd-justify_space-between emd-overflow_hidden"
    >
      <emd-alert
        v-if="errors.length"
        :title="$t('errorMsg')"
        :variant="'danger'"
        :autoclose="false"
        @cancel="errors = []"
      >
        <div slot="content">
          <p
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
      </emd-alert>

      <div
        v-if="isLoading || !employeeTypes"
        class="eu-pa_6 eu-align_center"
      >
        <emd-loader :type="'_invert'"></emd-loader>
      </div>

      <form
        v-else
        @submit.prevent="handleForm"
        id="form-user-invite"
        class="emd-flex-grow_1 emd-flex-shrink_1 emd-flex emd-overflow_hidden"
      >
        <div class="eu-mt_4 emd-flex-grow_1 emd-overflow-y_auto">
          <div
            class="emd-form-group"
            :class="{ _state_error: fields.email.hasError }"
          >
            <label class="emd-label">{{ $t('email') }}</label>
            <input
              class="emd-input eu-my_1"
              type="email"
              disabled
              :value="fields.email.value"
            />
          </div>
          <div
            class="emd-form-group"
            :class="{ _state_error: fields.first_name.hasError }"
          >
            <label
              class="emd-label"
              for="first_name"
              >{{ $t('firstName') }}</label
            >
            <input
              class="emd-input eu-my_1"
              id="first_name"
              type="text"
              @input="fields.first_name.errors = getFeedbackError('first_name')"
              :disabled="activeStep.inviteSetAsEmployee"
              v-model="fields.first_name.value"
            />
            <p
              v-for="(error, key) in fields.first_name.errors"
              :key="key"
              class="emd-input-feedback"
            >
              {{ error }}
            </p>
          </div>
          <div
            class="emd-form-group"
            :class="{ _state_error: fields.last_name.hasError }"
          >
            <label
              class="emd-label"
              for="last_name"
              >{{ $t('lastName') }}</label
            >
            <input
              class="emd-input eu-my_1"
              id="last_name"
              @input="fields.last_name.errors = getFeedbackError('last_name')"
              type="text"
              :disabled="activeStep.inviteSetAsEmployee"
              v-model="fields.last_name.value"
            />
            <p
              v-for="(error, key) in fields.last_name.errors"
              :key="key"
              class="emd-input-feedback"
            >
              {{ error }}
            </p>
          </div>
          <div
            class="emd-form-group"
            :class="{ _state_error: fields.employee_type.hasError }"
          >
            <label class="emd-label">{{ $t('permission') }}</label>
            <div class="emd-select">
              <select
                class="eu-my_1"
                v-model="fields.employee_type.value"
                @change="
                  fields.employee_type.errors =
                    getFeedbackError('employee_type')
                "
              >
                <option :value="null">{{ $t('select') }}</option>
                <option
                  v-for="(type, index) in employeeTypes"
                  :key="index"
                  :value="type.key"
                >
                  {{ type.value }}
                </option>
              </select>
              <div class="emd-select__arrow"></div>
            </div>
            <p
              v-for="(error, key) in fields.employee_type.errors"
              :key="key"
              class="emd-input-feedback"
            >
              {{ error }}
            </p>
          </div>
          <div
            class="emd-form-group"
            :class="{ _state_error: fields.password.hasError }"
          >
            <label class="emd-label">{{ $t('employeePassword') }}</label>
            <div class="emd-input-group">
              <input
                class="emd-input eu-my_1"
                :type="inputType"
                @input="fields.password.errors = getFeedbackError('password')"
                v-model="fields.password.value"
                autocomplete="current-password"
              />
              <a
                v-on:click="toggleInput()"
                class="emd-input-icon__link"
              >
                <i
                  class="uil uil-eye"
                  v-if="inputType === 'password'"
                />
                <i
                  class="uil uil-eye-slash"
                  v-if="inputType === 'text'"
                />
              </a>
            </div>
            <p
              v-for="(error, key) in fields.password.errors"
              :key="key"
              class="emd-input-feedback"
            >
              {{ error }}
            </p>
          </div>
        </div>
      </form>
    </div>

    <div
      class="emd-container__footer emd-ta_right"
      v-if="!isLoading"
    >
      <button
        class="emd-btn _color_primary _type_text eu-mr_2"
        @click="returnStepCheckUser"
        :class="{ _disabled: isLoading }"
        :disabled="isLoading"
      >
        {{ $t('return') }}
      </button>
      <button
        class="emd-btn _color_primary"
        form="form-user-invite"
        type="submit"
        :class="{ _disabled: isLoading }"
        :disabled="isLoading"
      >
        {{ activeStep.inviteSetAsEmployee ? $t('save') : $t('invite') }}
      </button>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "employeePassword": "Confirmação de senha",
    "requiredText": "Campo obrigatório",
    "emailFormatInvalid": "Formato de e-mail inválido.",
    "emailDomainInvalid": "E-mail com domínio inválido",
    "firstName": "Nome",
    "lastName": "Sobrenome",
    "permission": "Permissão Employee",
    "select": "Selecione uma opção",
    "invite": "Convidar",
    "errorMsg": "Erro",
    "errorPassword": "Senha incorreta, verifique e tente novamente.<br>Se errar 3 vezes nessa sessão, por medidas de segurança você será deslogado.",
    "errorDefaultInvite": "Erro ao convidar usuário Employee."
  },
  "en-US": {
    "employeePassword": "Password confirmation",
    "firstName": "First Name",
    "lastName": "Last Name",
    "permission": "Employee Permission",
    "select": "Select an option",
    "invite": "Invite",
    "errorMsg": "Error",
    "errorPassword": "Incorrect password, please check and try again.<br>If you make a mistake 3 times in this session, for security measures you will be logged out.",
    "errorDefaultInvite": "Error inviting Employee user."
  }
}
</i18n>

<script>
import EmdLoader from '@/components/emerald/atoms/EmdLoader'
import EmdAlert from '@/components/emerald/molecules/EmdAlert'

export default {
  name: 'FormInviteUser',
  components: {
    EmdLoader,
    EmdAlert
  },
  props: ['isOpen', 'fields', 'activeStep', 'userId'],
  data () {
    return {
      isLoading: false,
      errors: [],
      inputType: 'password'
    }
  },
  computed: {
    employeeTypes () {
      return this.$store.getters.employeeTypes
    }
  },
  methods: {
    toggleInput: function () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    },
    returnStepCheckUser () {
      const keys = Object.keys(this.fields).filter(key => key !== 'email')
      keys.forEach(key => {
        this.fields[key].hasError = false
        this.fields[key].errors = []
        this.fields[key].value = null
      })
      this.$emit('setActiveStep', 'checkUser')
    },
    getFeedbackError (inputName) {
      const errors = []
      const valueMissing = this.fields[inputName].value
        ? this.fields[inputName].value.isEmpty()
        : true

      if (valueMissing) {
        errors.push(this.$t('requiredText'))
      }

      this.fields[inputName].hasError = !!errors.length
      return errors
    },
    formatError (error) {
      const errorDefault =
        error.status === 412
          ? this.$t('errorPassword')
          : this.$t('errorDefaultInvite')
      const arrayOfArrays =
        error.body && error.body.notifications
          ? Object.values(error.body.notifications)
          : [[errorDefault]]
      return arrayOfArrays.reduce((a, b) => [...a, ...b], [])
    },
    inviteEmployee () {
      const params = {
        email: this.fields.email.value,
        first_name: this.fields.first_name.value,
        last_name: this.fields.last_name.value,
        employee_type: this.fields.employee_type.value,
        password: window.btoa(this.fields.password.value)
      }

      this.$store
        .dispatch('INVITE_EMPLOYEE', params)
        .then(response => {
          const userResponseData = {
            ...response,
            action: 'invite'
          }
          this.$emit('setUserData', userResponseData)
          this.$emit('setActiveStep', 'done')
        })
        .catch(error => {
          this.errors = this.formatError(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    inviteSetAsEmployee () {
      const params = {
        query: { userId: this.userId },
        body: {
          employee_type: this.fields.employee_type.value,
          password: window.btoa(this.fields.password.value)
        }
      }

      this.$store
        .dispatch('INVITE_SET_AS_EMPLOYEE', params)
        .then(() => {
          const userResponseData = {
            user: {
              first_name: this.fields.first_name.value,
              last_name: this.fields.last_name.value,
              email: this.fields.email.value
            },
            action: 'setAsEmployee'
          }
          this.$emit('setUserData', userResponseData)
          this.$emit('setActiveStep', 'done')
        })
        .catch(error => {
          this.errors = this.formatError(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    errorValidations (fields) {
      const keys = Object.keys(fields)
      let error = false
      keys.forEach(key => {
        fields[key].errors = this.getFeedbackError(key)
        fields[key].hasError = !!fields[key].errors.length
        error = fields[key].hasError || error
      })
      return error
    },
    handleForm () {
      if (this.isOpen) {
        const error = this.errorValidations(this.fields)
        if (!error) {
          this.isLoading = true
          this.errors = []

          if (this.activeStep.inviteSetAsEmployee) {
            this.inviteSetAsEmployee()
          } else {
            this.inviteEmployee()
          }
        }
      }
    }
  },
  mounted () {
    if (!this.employeeTypes) this.$store.dispatch('GET_EMPLOYEE_TYPES')
  }
}
</script>
