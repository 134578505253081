<template>
  <div>
    <div class="eu-pt_3">
      <div class="el-chunk">
        <button class="emd-btn _type_link"></button>
        <router-link
          :to="{ name: 'merchants', query: accountBackQuery }"
          tag="button"
          class="emd-btn _type_link"
        >
          <i class="uil uil-arrow-left" />
          {{ $t('back') }}
        </router-link>
      </div>
      <template v-if="selectedMerchant">
        <div class="eu-pb_1">
          <p
            href="javascript:void(0);"
            class="emd-text _size_subpage"
          >
            {{ selectedMerchant.name }}
          </p>
        </div>
        <div class="emd-text _size_small">
          <div class="emd-copy">
            <emd-copy :item="$route.params.merchant_id" />
          </div>
        </div>
      </template>
    </div>
    <div class="el-section">
      <div class="el-chunk">
        <div class="eu-flex eu-align-items_center">
          <div
            class="eu-flex-item eu-basis_12 eu-basis_7_md eu-mb_2 eu-no-margin_md"
          >
          <emd-form-group
            :state="inputFeedBackStatus"
            :label="$t('searchAccount')"
            :name="$t('searchAccount')"
            :feedbacks="[
              {
                msg: $t('inputMaxCharLimit'),
                condition: maxCharLimit
              }
            ]"
            >
            <div class="emd-input-group _wrapped">
              <emd-input
                type="text"
                :placeholder="$t('placeholderSearch')"
                name="accountSearch"
                class="emd-input"
                v-model="identifier"
                :disabled="loadingList"
                @keyup="$event => textChanged($event)"
                :maxLength="65"
              />
              <button
                @click="searchAccount(identifier)"
                class="emd-btn"
                :class="{ _color_secondary: identifier !== '' && !loadingList }"
              >
                <i class="uil uil-search" />
              </button>
            </div>
            </emd-form-group>
          </div>
          <div class="eu-flex-item eu-basis_12 eu-basis_4_md">
            <div class="emd-pagination _simple">
              <span
                class="emd-text _color_default _size_small"
                v-if="!loadingList"
                >{{ accounts.pagination.current_page }} {{ $t('of') }}
                {{ accounts.pagination.total_page }}</span
              >
              <div>
                <button
                  :disabled="
                    accounts.pagination.current_page === 1 || loadingList
                  "
                  @click="goToPage(accounts.pagination.current_page - 1)"
                  class="emd-btn _color_primary _type_text eu-mr_3"
                >
                  {{ $t('previous') }}
                </button>
                <button
                  :disabled="
                    accounts.pagination.current_page ===
                      accounts.pagination.total_page || loadingList
                  "
                  @click="goToPage(accounts.pagination.current_page + 1)"
                  class="emd-btn _color_primary _type_text"
                >
                  {{ $t('next') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-chunk">
        <nav class="emd-segmented-controls">
          <button
            class="emd-segmented-controls__item eu-basis_6"
            :class="{ _active: activeSegment === 'production' }"
            @click.prevent="toggleSegment('production')"
          >
            {{ $t('segmentProduction') }}
          </button>
          <button
            class="emd-segmented-controls__item eu-basis_6 _warning"
            @click.prevent="toggleSegment('test')"
            :class="{ _active: activeSegment === 'test' }"
          >
            {{ $t('segmentTest') }}
          </button>
        </nav>
      </div>
      <empty-list v-if="!loadingList && !accounts.items.length" />
      <div
        v-if="loadingList"
        class="eu-pa_6 eu-align_center"
      >
        <emd-loader :type="'_invert'"></emd-loader>
      </div>
      <div
        class="el-grid-list _auto-fill"
        v-else
      >
        <div
          class="emd-container _footer_end"
          v-for="account in accounts.items"
          :key="account.id"
        >
          <span
            class="emd-badge _position_top-right"
            :class="statusClass(account.status)"
            >{{ $t(account.status) }}</span
          >
          <div class="emd-container__content">
            <div class="eu-pb_1 eu-pr_6">
              <h3 class="emd-text _type_caption _size_section">
                {{ account.name }}
              </h3>
            </div>
            <div class="emd-text _size_small">
              <div class="emd-copy">
                <emd-copy :item="account.id" />
              </div>
            </div>
          </div>
          <div class="emd-container__footer">
            <a
              class="emd-btn _color_primary _type_link"
              :href="urlDash(account.id)"
            >
              <i class="uil uil-external-link-alt" />
              Dash
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "back": "voltar",
    "searchAccount": "Buscar Conta",
    "placeholderSearch": "Buscar por: Nome da Conta / ID / Secret Key",
    "helperSearch": "Pressione enter ou clique na lupa para buscar",
    "details": "Detalhes",
    "createIntegration": "Criar empresa para integração",
    "createNow": "Criar agora",
    "active": "Ativa",
    "inactive": "Inativa",
    "next": "próxima",
    "previous": "anterior",
    "of": "de",
    "segmentProduction": "Produção",
    "segmentTest": "Teste",
    "inputMaxCharLimit": "Campo com limite de 65 caracteres"
  },
  "en-US": {
    "back": "back",
    "searchAccount": "Search Account",
    "placeholderSearch": "Search for: Account name / ID / Secret key",
    "helperSearch": "Press enter or click the magnifier icon to search",
    "details": "Details",
    "createIntegration": "Create company for integration",
    "createNow": "Create now",
    "active": "Active",
    "inactive": "Inactive",
    "next": "next",
    "previous": "previous",
    "of": "of",
    "segmentProduction": "Production",
    "segmentTest": "Test",
    "inputMaxCharLimit": "Field limited to 65 characters"
  }
}
</i18n>

<script>
import EmdLoader from '@/components/emerald/atoms/EmdLoader'
import EmptyList from '@/components/project/globals/EmptyList'
import EmdCopy from '@/components/emerald/molecules/EmdCopy.vue'
import { EmdInput, EmdFormGroup } from 'emerald-vue/src/components'

export default {
  name: 'Accounts',
  components: {
    EmdLoader,
    EmptyList,
    EmdCopy,
    EmdInput,
    EmdFormGroup
  },
  data () {
    return {
      accounts: { items: [], pagination: {} },
      loadingList: true,
      identifier: '',
      activeSegment: 'production',
      maxCharLimit: false
    }
  },
  computed: {
    selectedMerchant () {
      return this.$store.getters.selectedMerchant
    },
    accountBackQuery () {
      return this.$store.getters.accountBackQuery
    },
    inputFeedBackStatus () {
      return this.maxCharLimit ? 'warning' : null
    }
  },
  methods: {
    textChanged (event) {
      const maximumLength = event.target.value.length >= 65

      if (event.keyCode === 13) {
        this.goToPage(1)
      }

      if (maximumLength) this.maxCharLimit = true
      else this.maxCharLimit = false
    },
    getAccounts (query) {
      this.loadingList = true
      query.page = query.page || 1
      query.size = query.size || 12
      query.merchantId = this.$route.params.merchant_id
      query.type = this.activeSegment
      this.$store.dispatch('GET_ACCOUNTS', query).then(res => {
        this.loadingList = false
        this.accounts = res
        const queryReplace = { page: query.page }
        if (query.identifier) queryReplace.identifier = query.identifier
        this.$router.replace({ query: queryReplace }).catch(err => err)
      })
    },
    getMerchant (id) {
      this.$store.dispatch('GET_MERCHANT_BY_ID', id).then(res => {
        this.$store.dispatch('SET_SELECTED_MERCHANT', {
          id: this.$route.params.merchant_id,
          name: res.name
        })
      })
    },
    goToPage (page) {
      const query = { page: page }
      if (this.identifier) {
        query.identifier = this.identifier
      }
      this.getAccounts(query)
    },
    searchAccount (identifier) {
      const query = { identifier: identifier }
      this.getAccounts(query)
    },
    urlDash (accId) {
      return `${process.env.VUE_APP_DASH_URL}/${this.$route.params.merchant_id}/${accId}`
    },
    statusClass (status) {
      status = status.toLowerCase()
      return {
        _color_success: status === 'active',
        _color_warning: status === 'inactive'
      }
    },
    toggleSegment (accountType) {
      this.activeSegment = accountType

      const query = this.$route.query || {}
      query.page = 1
      this.getAccounts(query)
    }
  },
  beforeMount () {
    const query = this.$route.query || { page: 1 }
    if (query.identifier) {
      this.$store.dispatch('revertSpecialChar', query.identifier).then(res => {
        this.identifier = res
      })
    } else {
      this.identifier = ''
    }
    if (!this.selectedMerchant) {
      this.getMerchant(this.$route.params.merchant_id)
    }
    this.getAccounts(query)
  }
}
</script>
