<template>
  <div
    v-if="loading"
    class="eu-pa_6 eu-align_center"
  >
    <emd-loader :type="'_invert'"></emd-loader>
  </div>
  <div
    v-else
    class="el-grid-list _auto-fill _size_large eu-mt_4">
    <template v-if="permissions.length">
      <permission-card
        v-for="permission in permissions"
        :key="permission.id"
        :permission="permission"
        @openModalChangeDefault="openModalChangeDefault"
      />
    </template>
    <template v-else>
      <permission-card isEmpty></permission-card>
    </template>
  </div>
</template>

<script>
import PermissionCard from './PermissionCard.vue'
import EmdLoader from '@/components/emerald/atoms/EmdLoader.vue'

export default {
  name: 'PermissionWrapper',
  props: {
    permissions: {
      type: Array,
      default: function () {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    EmdLoader,
    PermissionCard
  },

  methods: {
    openModalChangeDefault (permission) {
      this.$emit('openModalChangeDefault', permission)
    }
  }
}
</script>
