<template>
  <div
    class="eu-text-overflow emd-copy"
    :class="{ _copied: copied, 'eu-row_reverse': textLeft }"
    @click="copy(item)"
  >
    <span
      class="emd-copy__icon"
      :title="copied ? $t('copied') : $t('copy')"
    >
      <i
        class="uil uil-check-circle"
        v-if="copied"
      />
      <i
        class="uil uil-copy"
        v-else
      />
    </span>
    <p class="er-copy__text emd-copy__text">
      <span>
        <template v-if="showLabel">
          {{ item }}
        </template>
      </span>
    </p>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "copy": "copiar",
    "copied": "copiado"
  },
  "en-US": {
    "copy": "copy",
    "copied": "copied"
  }
}
</i18n>

<script>
export default {
  name: 'EmdCopy',
  props: {
    item: {
      type: String,
      default: null,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    textLeft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      copied: false
    }
  },
  methods: {
    copy (item) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(item)
          .then(() => {
            this.copied = true
            setTimeout(() => {
              this.copied = false
            }, 1000)
          })
          .catch(error => {
            throw new Error(error)
          })
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = item
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
          document.execCommand('copy')
        } catch (err) {
          throw new Error(err)
        }

        document.body.removeChild(textArea)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 1000)
      }
    }
  }
}
</script>
