<template>
  <Modal :open="isOpen">
    <template slot="content">
      <div
        class="emd-container _highlight_header _footer_end _footer_spaced_mobile"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('inviteAdmin') }}
          </h2>
          <button
            @click="hideInviteCancelled"
            class="emd-btn _type_link"
          >
            <i class="uil uil-times" />
          </button>
        </div>
        <form @submit.prevent="checkForm">
          <div class="emd-container__content">
            <template v-if="activeStep.inviteUser">
              <div class="emd-form-group">
                <label class="emd-label">{{ $t('labelUserFirstName') }}</label>
                <input
                  class="emd-input"
                  type="text"
                  :class="{ _state_error: fields.first_name.hasError }"
                  v-model="fields.first_name.value"
                />
              </div>
              <div class="emd-form-group">
                <label class="emd-label">{{ $t('labelUserLastName') }}</label>
                <input
                  class="emd-input"
                  type="text"
                  :class="{ _state_error: fields.last_name.hasError }"
                  v-model="fields.last_name.value"
                />
              </div>
              <div class="emd-form-group">
                <label class="emd-label">{{ $t('labelUserEmail') }}</label>
                <input
                  class="emd-input"
                  type="email"
                  :class="{ _state_error: fields.email.hasError }"
                  v-model="fields.email.value"
                />
              </div>
            </template>
            <template v-if="activeStep.searchMerchant">
              <emd-alert
                :variant="'danger'"
                v-if="errors.length"
                @cancel="errors = []"
              >
                <template slot="content">
                  <p
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </template>
              </emd-alert>
              <div class="emd-interactive-list">
                <div class="emd-interactive-list__search">
                  <span class="emd-interactive-list__title">
                    <span
                      class="emd-text _type_label _color_default _size_small"
                      >{{ $t('labelSearchMerch') }}</span
                    >
                  </span>
                  <div class="emd-input-group">
                    <input
                      class="emd-input"
                      type="text"
                      :placeholder="$t('placeholderSearch')"
                      v-model="merchantIdentifier"
                      @keyup="textChanged"
                    />
                    <i
                      class="uil uil-search emd-btn _type_link _color_primary"
                      @click="fetchMerch(merchantIdentifier, 1, 0)"
                    />
                  </div>
                  <p class="emd-input-feedback">{{ $t('helperSearch') }}</p>
                </div>
                <div
                  v-if="merchantsIsLoading"
                  class="eu-pa_6 eu-align_center"
                >
                  <emd-loader :type="'_invert'"></emd-loader>
                </div>
                <div
                  class="emd-interactive-list__main"
                  v-else
                >
                  <div
                    v-if="merchants && merchants.length > 0"
                    class="emd-select-list"
                  >
                    <ul>
                      <li
                        class="emd-select-list__item"
                        v-for="(merchant, index) in merchants"
                        :key="index"
                        @click="selectMerchant(merchant)"
                        v-bind:class="{
                          _active:
                            selectedMerchant && merchant.id === selectedMerchant
                        }"
                      >
                        <span class="emd-select-list__label">
                          {{ merchant.name }}
                        </span>
                        <span
                          class="emd-select-list__select-icon"
                          v-if="
                            selectedMerchant && merchant.id === selectedMerchant
                          "
                          style="line-height: 1em"
                        >
                          <i class="uil uil-check-circle" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="emd-interactive-list__pagination">
                  <button
                    class="emd-btn _type_text _color_primary"
                    @click.stop="previousPage"
                    :class="{
                      _disabled: merchantsIsLoading || currentPage <= 1
                    }"
                    :disabled="merchantsIsLoading || currentPage === 1"
                  >
                    {{ $t('previous') }}
                  </button>
                  <span class="emd-text _size_tiny _align_center">
                    {{ currentPage }} {{ $t('of') }} {{ totalPages }}
                  </span>
                  <button
                    class="emd-btn _type_text _color_primary"
                    @click.stop="nextPage"
                    :class="{
                      _disabled: merchantsIsLoading || currentPage >= totalPages
                    }"
                    :disabled="merchantsIsLoading || currentPage >= totalPages"
                  >
                    {{ $t('next') }}
                  </button>
                </div>
              </div>
              <hr class="emd-divider eu-my_3" />
              <div class="emd-form-group">
                <label class="emd-label">{{
                  $t('labelEmployeePassword')
                }}</label>
                <div class="emd-input-group">
                  <input
                    class="emd-input"
                    :class="{ _state_error: password.hasError }"
                    :type="inputType"
                    v-model="password.value"
                    autocomplete="current-password"
                  />
                  <a
                    v-on:click="toggleInput()"
                    class="emd-input-icon__link"
                  >
                    <i
                      class="uil uil-eye"
                      v-if="inputType === 'password'"
                    />
                    <i
                      class="uil uil-eye-slash"
                      v-if="inputType === 'text'"
                    />
                  </a>
                </div>
              </div>
            </template>
            <template v-if="activeStep.done">
              <div class="eu-align_center eu-py_2">
                <div class="el-chunk">
                  <div class="el-chunk">
                    <h3 class="emd-text _size_subpage _color_default">
                      {{ $t('success.title') }}
                    </h3>
                  </div>
                  <p class="emd-text _color_subtle">
                    {{ $t('success.subtitle') }}
                  </p>
                </div>
              </div>
              <div class="emd-panel">
                <div class="emd-panel__section">
                  <div class="emd-panel__block">
                    <div class="el-chunk">
                      <p class="emd-text _type_caption">
                        {{ $t('success.informations') }}
                      </p>
                    </div>
                  </div>
                  <div class="emd-panel__block">
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.name') }}
                      </h4>
                      <p>
                        {{ inviteUserResponse.user.first_name }}
                        {{ inviteUserResponse.user.last_name }}
                      </p>
                    </div>
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.email') }}
                      </h4>
                      <p>{{ inviteUserResponse.user.email }}</p>
                    </div>
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.company') }}
                      </h4>
                      <p>{{ this.selectedMerchantName }}</p>
                    </div>
                    <div class="el-chunk">
                      <h4
                        class="emd-text _type_label _size_small _color_subtle"
                      >
                        {{ $t('success.permission') }}
                      </h4>
                      <span class="emd-badge _color_info">{{
                        $t('success.admin')
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emd-panel">
                <div class="emd-panel__section">
                  <div class="emd-panel__block"></div>
                  <div class="emd-panel__block">
                    <ul class="emd-list _inline _space_base">
                      <li>
                        <a
                          :href="urlCompany"
                          target="_blank"
                          :title="$t('success.seeInvite')"
                          class="emd-btn _color_primary"
                        >
                          {{ $t('success.seeInvite') }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            class="emd-container__footer"
            v-if="!activeStep.done"
          >
            <button
              class="emd-btn _type_text _color_primary"
              v-if="activeStep.inviteUser"
              type="button"
              @click="hideInviteCancelled"
            >
              {{ $t('cancel') }}
            </button>
            <button
              class="emd-btn _type_text _color_primary"
              v-if="activeStep.searchMerchant"
              type="button"
              @click="goBack"
            >
              {{ $t('back') }}
            </button>
            <button
              class="emd-btn _color_primary"
              v-if="activeStep.inviteUser"
              type="submit"
            >
              {{ $t('continue') }}
            </button>
            <button
              class="emd-btn _color_primary"
              v-if="activeStep.searchMerchant"
              type="submit"
              :class="{ _disabled: inviteIsLoading }"
              :disabled="inviteIsLoading"
            >
              {{ $t('invite') }}
            </button>
            <button
              class="emd-btn _color_primary"
              v-if="activeStep.done"
              type="button"
              @click="hideInviteSuccess"
            >
              {{ $t('done') }}
            </button>
          </div>
        </form>
      </div>
    </template>
  </Modal>
</template>

<i18n>
{
  "pt-BR": {
    "inviteAdmin": "Convidar Administrador",
    "progress": {
      "userData": "Informações<br>do usuário",
      "merchData": "Selecionar<br>empresa",
      "inviteSent": "Convite<br>enviado"
    },
    "labelUserFirstName": "Nome",
    "labelUserLastName" : "Sobrenome",
    "labelUserEmail": "E-mail",
    "labelSearchMerch": "Buscar empresa",
    "placeholderSearch": "Buscar...",
    "helperSearch": "Pressione enter ou clique na lupa para buscar",
    "search": "Buscar",
    "next": "próxima",
    "previous": "anterior",
    "of": "de",
    "labelEmployeePassword": "Confirmação de senha",
    "cancel": "Cancelar",
    "back": "Voltar",
    "continue": "Continuar",
    "invite": "Convidar",
    "done": "Concluir",
    "success": {
      "title": "Convite enviado com sucesso!",
      "subtitle": "Confira abaixo os dados do convite:",
      "informations": "Informações",
      "name": "Nome",
      "email": "E-mail",
      "company": "Empresa",
      "permission": "Permissão",
      "admin": "Administrador",
      "seeInvite": "Ver no Company"
    },
    "errorDefaultInvite": "Erro ao convidar Administrador."
  },
  "en-US": {
    "inviteAdmin": "Invite admin",
    "progress": {
      "userData": "User<br>information",
      "merchData": "Select<br>merchant",
      "inviteSent": "Invite<br>sent"
    },
    "labelUserFirstName": "First name",
    "labelUserLastName" : "Last name",
    "labelUserEmail": "E-mail",
    "labelSearchMerch": "Search merchant",
    "placeholderSearch": "Search...",
    "helperSearch": "Press enter or click the magnifier icon to search",
    "search": "Search",
    "next": "next",
    "previous": "previous",
    "of": "of",
    "labelEmployeePassword": "Password confirmation",
    "cancel": "Cancel",
    "back": "Back",
    "continue": "Continue",
    "invite": "Invite",
    "done": "Done",
    "success": {
      "title": "Invite was successfully sent!",
      "subtitle": "See summary below:",
      "informations": "Informations",
      "name": "Name",
      "email": "E-mail",
      "company": "Company",
      "permission": "Permission",
      "admin": "Admin",
      "seeInvite": "See on Company"
    },
    "errorDefaultInvite": "Error inviting Admin user."
  }
}
</i18n>

<script>
import Modal from '@/components/project/globals/Modal.vue'
import EmdLoader from '@/components/emerald/atoms/EmdLoader'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'

export default {
  name: 'ModalInviteAdmin',
  components: {
    Modal,
    EmdLoader,
    EmdAlert
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.selectedMerchant}/admin/invite`
    }
  },
  data () {
    return {
      inputType: 'password',
      errors: [],
      activeStep: {
        inviteUser: true,
        searchMerchant: false,
        done: false
      },
      fields: {
        first_name: {
          required: true,
          hasError: false,
          value: null
        },
        last_name: {
          required: true,
          hasError: false,
          value: null
        },
        email: {
          required: true,
          hasError: false,
          value: null
        }
      },
      password: {
        required: true,
        hasError: false,
        value: null
      },
      selectedMerchant: null,
      selectedMerchantName: null,
      inviteIsLoading: false,
      merchantsIsLoading: false,
      inviteUserResponse: null,
      merchants: null,
      currentPage: null,
      totalPages: null,
      merchantIdentifier: ''
    }
  },
  methods: {
    textChanged (event) {
      if (event.keyCode === 13) {
        this.fetchMerch(this.merchantIdentifier, 1, 0)
      }
    },
    goToStep (step) {
      if (this.activeStep.done) return
      this.activeStep.done = false
      if (step === 'inviteUser') {
        this.activeStep.inviteUser = true
        this.activeStep.searchMerchant = false
      } else if (step === 'searchMerchant') {
        this.checkForm()
      }
    },
    toggleInput: function () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    },
    goBack () {
      this.activeStep.inviteUser = true
      this.activeStep.searchMerchant = false
      this.activeStep.done = false
    },
    hideInviteCancelled () {
      if (this.isOpen) {
        this.resetForm()
        this.$emit('closeModal', { invited: false })
      }
    },
    hideInviteSuccess () {
      if (this.isOpen) {
        this.resetForm()
        this.$emit('closeModal', { invited: true })
      }
    },
    resetForm () {
      const keys = Object.keys(this.fields)
      keys.forEach(key => {
        this.fields[key].hasError = false
        this.fields[key].value = null
      })
      this.password.value = null
      this.password.hasError = false
      this.selectedMerchant = null
      this.selectedMerchantName = null
      this.merchantIdentifier = null
      this.errors = []
      this.activeStep.inviteUser = true
      this.activeStep.searchMerchant = false
      this.activeStep.done = false
    },
    checkForm () {
      if (this.isOpen) {
        if (this.activeStep.inviteUser) {
          const keys = Object.keys(this.fields)
          let error = false
          keys.forEach(key => {
            this.fields[key].hasError = this.fields[key].value
              ? this.fields[key].value.isEmpty()
              : true
            if (key === 'email') {
              this.fields[key].hasError = this.fields[key].value
                ? !this.fields[key].value.validEmail()
                : true
            }
            error = this.fields[key].hasError || error
          })
          if (!error) {
            this.activeStep.inviteUser = false
            this.activeStep.searchMerchant = true
            if (this.merchants === null) {
              this.fetchMerch(null, 1)
            }
          }
        } else if (this.activeStep.searchMerchant) {
          this.inviteUser()
        }
      }
    },
    inviteUser () {
      this.password.hasError = this.password.value
        ? this.password.value.isEmpty()
        : true
      if (!this.password.hasError) {
        this.inviteIsLoading = true
        const params = {
          merchant: {
            id: this.selectedMerchant,
            type: 'Admin'
          },
          user: {
            email: this.fields.email.value,
            first_name: this.fields.first_name.value,
            last_name: this.fields.last_name.value
          },
          password: window.btoa(this.password.value)
        }
        this.$store
          .dispatch('INVITE_USER', params)
          .then(response => {
            this.inviteUserResponse = response
            this.activeStep.searchMerchant = false
            this.activeStep.done = true
            this.inviteIsLoading = false
          })
          .catch(error => {
            this.inviteIsLoading = false
            const arrayOfArrays =
              error.body && error.body.notifications
                ? Object.values(error.body.notifications)
                : [[this.$t('errorDefaultInvite')]]
            this.errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
          })
      }
    },
    fetchMerch (name, page, delay = 750) {
      this.merchantsIsLoading = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        var params = {}
        if (name) {
          params.identifier = name
        }
        params.page = page
        params.size = 5
        this.$store
          .dispatch('GET_MERCHANTS', params)
          .then(response => {
            this.totalPages = response.pagination.total_page
            this.merchants = response.items
            this.currentPage = response.pagination.current_page
            this.merchantsIsLoading = false
          })
          .catch(() => {
            this.totalPages = null
            this.merchants = null
            this.currentPage = null
            this.merchantsIsLoading = false
          })
      }, delay)
    },
    previousPage () {
      if (this.currentPage <= 1) return
      this.currentPage = this.currentPage - 1
      this.fetchMerch(this.merchantIdentifier, this.currentPage)
    },
    nextPage () {
      if (this.currentPage >= this.totalPages) return
      this.currentPage = this.currentPage + 1
      this.fetchMerch(this.merchantIdentifier, this.currentPage)
    },
    selectMerchant (selected) {
      this.selectedMerchant = selected.id
      this.selectedMerchantName = selected.name
    }
  }
}
</script>
