const formatData = (isoDate) => {
  const date = new Date(isoDate)
  
  return date.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
}

export default formatData