<template>
  <Modal :open="isOpen">
    <template slot="content">
      <div
        class="emd-container _highlight_header _footer_end _footer_spaced_mobile"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section _color_secondary">
            {{
              isActionSetAsEmployee
                ? $t('inviteSetAsEmployee')
                : $t('inviteEmployee')
            }}
          </h2>
          <button
            @click="hideInviteEmployeeCancelled"
            class="emd-btn _type_link"
          >
            <i class="uil uil-times" />
          </button>
        </div>

        <form-check-user
          v-if="activeStep.checkUser"
          :fields="fields"
          @setActiveStep="setActiveStep"
          @setDataCheckUser="setDataCheckUser"
          @hideCancelled="hideInviteEmployeeCancelled"
        />

        <form-invite-user
          v-else-if="activeStep.inviteUser || activeStep.inviteSetAsEmployee"
          :fields="fields"
          :userId="userId"
          :activeStep="activeStep"
          :isOpen="isOpen"
          @setActiveStep="setActiveStep"
          @setUserData="setUserResponseData"
        />

        <done-step
          v-else-if="activeStep.done && userResponseData"
          :user="userResponseData.user"
          :permission="fields.employee_type.value"
          :action="userResponseData.action"
          @done="hideInviteEmployeeSuccess"
        />
      </div>
    </template>
  </Modal>
</template>

<i18n>
{
  "pt-BR": {
    "inviteEmployee": "Convidar usuário Employee",
    "inviteSetAsEmployee": "Atribuir permissão Employee"
  },
  "en-US": {
    "inviteEmployee": "Invite Employee User",
    "inviteSetAsEmployee": "Assign Employee Permission"
  }
}
</i18n>

<script>
import Modal from '@/components/project/globals/Modal.vue'
import DoneStep from '@/components/project/globals/ModalInviteEmployee/InviteDone'
import FormCheckUser from '@/components/project/globals/ModalInviteEmployee/CheckUserInvite'
import FormInviteUser from '@/components/project/globals/ModalInviteEmployee/InviteUser'

export default {
  name: 'ModalInviteEmployee',
  components: {
    FormInviteUser,
    Modal,
    DoneStep,
    FormCheckUser
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputType: 'password',
      activeStep: {
        checkUser: true,
        inviteUser: false,
        inviteSetAsEmployee: false,
        done: false
      },
      userResponseData: null,
      userId: null,
      fields: {
        password: {
          required: true,
          hasError: false,
          errors: [],
          value: null
        },
        first_name: {
          required: true,
          hasError: false,
          errors: [],
          value: null
        },
        last_name: {
          required: true,
          hasError: false,
          errors: [],
          value: null
        },
        employee_type: {
          required: true,
          hasError: false,
          errors: [],
          value: null
        },
        email: {
          required: true,
          hasError: false,
          errors: [],
          value: null
        }
      }
    }
  },
  computed: {
    employeeTypes () {
      return this.$store.getters.employeeTypes
    },
    isActionSetAsEmployee () {
      return (
        this.activeStep.inviteSetAsEmployee ||
        (this.userResponseData &&
          this.userResponseData.action === 'setAsEmployee')
      )
    }
  },
  methods: {
    setDataCheckUser ({ userId, email, firstName, lastName }) {
      this.fields.email.value = email
      this.fields.first_name.value = firstName
      this.fields.last_name.value = lastName
      this.userId = userId
    },
    setUserResponseData (userData) {
      this.userResponseData = userData
    },
    hideInviteEmployeeCancelled () {
      if (this.isOpen) {
        this.$emit('closeModal', { invited: false })
      }
    },
    hideInviteEmployeeSuccess () {
      if (this.isOpen) {
        this.$emit('closeModal', { invited: true })
      }
    },
    setActiveStep (step) {
      const keys = Object.keys(this.activeStep)
      keys.forEach(key => {
        this.activeStep[key] = key === step
      })
    },
    resetForm () {
      const keys = Object.keys(this.fields)
      keys.forEach(key => {
        this.fields[key].hasError = false
        this.fields[key].errors = []
        this.fields[key].value = null
      })
      this.userId = null
      this.userResponseData = null
    }
  },
  mounted () {
    this.resetForm()
    if (!this.employeeTypes) this.$store.dispatch('GET_EMPLOYEE_TYPES')
  }
}
</script>
