import { BackOfficeInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

const commons = {
  state: {
    globalLoading: true,
    globalError: false,
    globalErrorCode: '',
    pref: null,
    backofficeTheme: 'pagarme',
    employeeTypes: null
  },
  getters: {
    globalLoading (state) {
      return state.globalLoading
    },
    backofficeTheme (state) {
      return state.backofficeTheme
    },
    pref (state) {
      return state.pref
    },
    employeeTypes (state) {
      return state.employeeTypes
    }
  },
  mutations: {
    toggleGlobalLoading (state, status) {
      state.globalLoading = status
    },
    toggleGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
    },
    setBackofficeTheme (state, theme) {
      document.body.classList.remove(state.backofficeTheme)
      state.backofficeTheme = theme
      document.body.classList.add(theme)
    },
    setPref (state, value) {
      state.pref = value
    },
    setEmployeeTypes (state, value) {
      state.employeeTypes = value
    }
  },
  actions: {
    toggleGlobalLoading ({ commit }, status) {
      commit('toggleGlobalLoading', status)
    },
    revertSpecialChar (context, value) {
      return value
        .replace('%21', '!')
        .replace('%22', '"')
        .replace('%23', '#')
        .replace('%24', '$')
        .replace('%26', '&')
        .replace('%27', "'")
        .replace('%28', '(')
        .replace('%29', ')')
        .replace('%2A', '*')
        .replace('%2B', '+')
        .replace('%2C', ',')
        .replace('%2D', '-')
        .replace('%2E', '.')
        .replace('%2F', '/')
        .replace('%3A', ':')
        .replace('%3B', ';')
        .replace('%3C', '<')
        .replace('%3D', '=')
        .replace('%3E', '>')
        .replace('%3F', '?')
        .replace('%40', '@')
    },
    SET_BACKOFFICE_THEME (context, theme) {
      window.$cookies.set(
        `${BackOfficeInstance.envAcronym}mp_bo`,
        window.btoa(JSON.stringify({ theme: theme })),
        null,
        '/',
        BackOfficeInstance.domain
      )
      context.commit('setBackofficeTheme', theme)
    },
    GET_PREFERENCES ({ commit, state }) {
      let pref = window.$cookies.get(
        `${BackOfficeInstance.envAcronym}mp_pref`,
        '/',
        BackOfficeInstance.domain
      )
      pref = pref
        ? JSON.parse(window.atob(pref))
        : {
            timezone: 'E. South America Standard Time',
            language: 'pt-BR',
            theme: 'light',
            appearance: { theme: 'pagarme', mode: 'light' }
          }
      commit('setPref', pref)
    },
    SET_LANGUAGE ({ dispatch, state }, lang) {
      const preferences = Object.assign(
        {},
        JSON.parse(JSON.stringify(state.pref))
      )
      preferences.language = lang
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_THEME_MODE ({ dispatch, state }, mode) {
      const preferences = Object.assign(
        {},
        JSON.parse(JSON.stringify(state.pref))
      )
      preferences.appearance.mode = mode
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_PREFERENCES (context, pref) {
      window.$cookies.set(
        `${BackOfficeInstance.envAcronym}mp_pref`,
        window.btoa(JSON.stringify(pref)),
        null,
        '/',
        BackOfficeInstance.domain
      )
      if (context.getters.pref.language !== pref.language) location.reload()
      context.commit('setPref', pref)
    },
    GO_TO_HOME () {
      window.location.assign('/')
    },
    GET_EMPLOYEE_TYPES (context) {
      return BackOfficeInstance.commons
        .getEmployeeTypes()
        .then(response => {
          context.commit('setEmployeeTypes', response.employee_types)
          return Promise.resolve(response)
        })
        .catch(error => catchError(error, context))
    }
  }
}

export default commons
