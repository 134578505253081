<template>
  <main class="el-core">
    <app-header
      @drawerMenuClicked="toggleDrawerMenu"
      :drawerOpen="drawerMenuOpen"
    />
    <div class="el-core__wrapper">
      <div class="el-main _navigation_horizontal">
        <div class="el-main__navigation">
          <div class="eu-pt_2">
            <emd-tabbar />
          </div>
        </div>
        <div class="el-main__content">
          <div class="el-bounds">
            <div class="el-section">
              <router-view :key="$route.path"></router-view>
            </div>
            <div class="el-section">
              <emd-footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AppHeader from '@/components/project/ui/AppHeader'
import EmdTabbar from '@/components/emerald/organisms/EmdTabbar'
import EmdFooter from '@/components/emerald/organisms/EmdFooter'
export default {
  name: 'Webapp',
  components: {
    AppHeader,
    EmdTabbar,
    EmdFooter
  },
  data () {
    return {
      userMenuState: false,
      drawerMenuOpen: false,
      links: ['merchants', 'users']
    }
  },
  computed: {
    appVersion () {
      return this.$store.getters.appVersion
    }
  },
  mounted () {
    var meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  methods: {
    toggleUserMenu () {
      this.userMenuState = !this.userMenuState
    },
    toggleDrawerMenu () {
      this.drawerMenuOpen = !this.drawerMenuOpen
    }
  }
}
</script>
