import { catchError } from '@/support/errorLogger.js'
import { BackOfficeInstance } from '@/store'

const user = {
  state: {
    user: null,
    selectedUser: null,
    userBackQuery: {},
    tfaEnabled: false
  },
  getters: {
    selectedUser (state) {
      return state.selectedUser
    },
    user (state) {
      return state.user
    },
    userBackQuery (state) {
      return state.userBackQuery
    },
    hasPermission (state) {
      const users = process.env.VUE_APP_INVITE_ROOT_PERMISSION.replace(
        /\s/g,
        ''
      ).split(',')
      return users.includes(state.user.email)
    }
  },
  mutations: {
    setUser (state, user) {
      if (user) {
        const nameSplit = user.last_name.split(' ')
        const lastNameSplit = nameSplit[nameSplit.length - 1]
        user.initials = `${user.first_name
          .charAt(0)
          .toUpperCase()}${lastNameSplit.charAt(0).toUpperCase()}`
      }
      state.user = user
    },
    setSelectedUser (state, user) {
      state.selectedUser = user
    },
    setUserBackQuery (state, value) {
      state.userBackQuery = value
    },
    setTfaState (state, value) {
      state.tfaEnabled = value
    }
  },
  actions: {
    SET_USER ({ commit }, user) {
      commit('setUser', user)
    },
    SET_SELECTED_USER ({ commit }, user) {
      commit('setSelectedUser', user)
    },
    GET_USERS (context, params = {}) {
      return BackOfficeInstance.user
        .getAll(params)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    GET_USERS_ONE (context, params = {}) {
      return BackOfficeInstance.user
        .getAllPagarmeUsers(params)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    GET_USERS_EMPLOYEES (context, params = {}) {
      return BackOfficeInstance.user
        .getEmployees(params)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    GET_USER_BY_ID (context, userId) {
      return BackOfficeInstance.user
        .getById(userId)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    GET_USER_PERMISSIONS (context, userId) {
      return BackOfficeInstance.user
        .getPermissions(userId)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    GET_PAGARME_USER_PERMISSIONS (context, userId) {
      return BackOfficeInstance.user
        .getPagarmePermissions(userId)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    SET_USER_DEFAULT (context, params = {}) {
      return BackOfficeInstance.user
        .setDefault(params.user_id, params.params, params.body)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    DELETE_EMPLOYEE (context, params = {}) {
      return BackOfficeInstance.user
        .deleteEmployee(params.user_id, params.body)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    DELETE_TFA (context, userId) {
      return BackOfficeInstance.user.deleteMfa(userId)
    },
    SET_USER_BACK_QUERY ({ commit }, query) {
      commit('setUserBackQuery', query)
    }
  }
}

export default user
