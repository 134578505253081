<template>
  <div class="emd-flex-grow_1 emd-flex-shrink_1 emd-flex emd-overflow_hidden">
    <div
      class="emd-container__content emd-flex emd-justify_space-between emd-overflow_hidden"
    >
      <emd-alert
        :variant="'success'"
        :autoclose="false"
      >
        <div slot="content">
          <p v-if="action === 'setAsEmployee'">
            {{ $t('success.titleSetAsEmployee') }}
          </p>
          <p v-else>{{ $t('success.titleInvite') }}</p>
        </div>
      </emd-alert>

      <div class="eu-mt_5">
        <div class="el-chunk">
          <p class="emd-text _type_caption _color_contrast">
            {{ $t('success.informations') }}
          </p>
        </div>
        <div class="el-chunk">
          <h4 class="emd-text _type_caption _color_contrast eu-mb_1">
            {{ $t('success.name') }}
          </h4>
          <p class="emd-text _color_subtle">
            {{ user.first_name }} {{ user.last_name }}
          </p>
        </div>
        <div class="el-chunk">
          <h4 class="emd-text _type_caption _color_contrast eu-mb_1">
            {{ $t('success.email') }}
          </h4>
          <p class="emd-text _color_subtle">{{ user.email }}</p>
        </div>
        <div class="el-chunk">
          <h4 class="emd-text _type_caption _color_contrast eu-mb_2">
            {{ $t('success.permission') }}:
          </h4>
          <span class="emd-badge">{{ permission }}</span>
        </div>
      </div>
    </div>

    <div class="emd-container__footer emd-ta_right">
      <button
        class="emd-btn _color_primary"
        type="button"
        @click="$emit('done')"
      >
        {{ $t('done') }}
      </button>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "success": {
      "titleInvite": "Convite enviado com sucesso!",
      "titleSetAsEmployee": "Permissão atribuída com sucesso!",
      "informations": "Informações do usuário convidado:",
      "name": "Nome",
      "email": "E-mail",
      "permission": "Permissão"
    }
  },
  "en-US": {
    "success": {
      "titleInvite": "Invite was successfully sent!",
      "titleSetAsEmployee": "Permissão atribuída com sucesso!",
      "subtitle": "See summary below:",
      "informations": "Guest User Information:",
      "name": "Name",
      "email": "E-mail",
      "permission": "Permission"
    }
  }
}
</i18n>

<script>
import EmdAlert from '@/components/emerald/molecules/EmdAlert'

export default {
  name: 'DoneStep',
  components: {
    EmdAlert
  },
  props: {
    permission: {
      type: String,
      required: false,
      default: ''
    },
    action: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
