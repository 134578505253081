<template>
  <div>
    <modal-password
      v-if="passwordModalIsOpen"
      :callback="deleteUser"
      @cancel="passwordModalIsOpen = false"
    />
    <modal-change-default
      v-if="changeDefaultIsOpen"
      :isOpen="changeDefaultIsOpen"
      :merchant="merchantSelected"
      @closeModal="hideChangeDefault"
    />
    <div class="eu-pt_3">
      <div class="el-chunk">
        <router-link
          :to="{ name: originRouteName, query: userQuery }"
          class="emd-btn _type_link"
          tag="button"
        >
          <i class="uil uil-arrow-left"/>
          {{ $t('back') }}
        </router-link>
      </div>
      <template v-if="selectedUser">
        <emd-alert
          v-if="showAlertUserDeleted"
          variant="success"
          @cancel="showAlertUserDeleted = false"
        >
          <template slot="content">
            <p>{{ $t('userDeleted') }}</p>
          </template>
        </emd-alert>

        <data-display
          :sectionName="`${selectedUser.first_name} ${selectedUser.last_name}`"
          :status="userStatus"
          :tableData="profileData"
        >
          <button
            v-if="showBtnRemove"
            class="emd-btn _type_outline _color_danger eu-mt_3"
            @click="passwordModalIsOpen = true"
          >
            <i class="uil uil-trash"/>
            {{ $t('remove') }}
          </button>
        </data-display>

        <data-display
          :sectionName="$t('mfaSectionTitle')"
          :status="mfaStatus"
          :tableData="mfaData"
        >
          <tfa-section />
        </data-display>
      </template>
    </div>

    <div
      class="el-section">
      <emd-tabs
        :tabs="tabs"
        @changed="changed"
      >
        <template v-slot:permissions_mundi>
          <permissions-wrapper
            :loading="loadingList"
            :permissions="permissions"
            @openModalChangeDefault="openModalChangeDefault"
          />
        </template>

        <template #permissions_pagarme>
          <permissions-wrapper
            :loading="loadingList"
            :permissions="permissions_pagarme"
            @openModalChangeDefault="openModalChangeDefault"
          />
        </template>
      </emd-tabs>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "back": "Voltar",
    "remove": "Remover",
    "defaultAccount": "Conta padrão",
    "permission": "Permissão",
    "permissionMundi": "Permissão 2.0",
    "permissionPagarme": "Permissão 1.0",
    "configCompany": "Configurar no Company",
    "primaryAccess": "Acesso principal",
    "setAsPrimary": "Definir como principal",
    "userDeleted": "Usuário removido com sucesso.",
    "userStatusActive": "Ativo",
    "userStatusInactive": "Inativo",
    "userId": "ID do usuário",
    "userEmail": "E-mail",
    "userDocument": "Documento",
    "userProfile": "Perfil de acesso",
    "whatsappFallback": "Fallback por WhatsApp",
    "mfaSms": "Configurada por SMS",
    "mfaApp": "Configurada por App Autenticador",
    "mfaSectionTitle": "Autenticação em dois fatores",
    "accept": "Aceito em"
  },
  "en-US": {
    "back": "Back",
    "remove": "Remove",
    "defaultAccount": "Default account",
    "searchUser": "Search User",
    "permission": "Permission",
    "permissionMundi": "Permission 2.0",
    "permissionPagarme": "Permission 1.0",
    "configCompany": "Configure on Company",
    "primaryAccess": "Primary access",
    "setAsPrimary": "Set as primary",
    "userDeleted": "User removed successfully.",
    "userStatusActive": "Active",
    "userStatusInactive": "Inactive",
    "userId": "User ID",
    "userEmail": "E-mail",
    "userDocument": "Document",
    "userProfile": "Access Profile",
    "whatsappFallback": "WhatsApp Fallback",
    "mfaSms": "Configured via SMS",
    "mfaApp": "Configured via Authenticator App",
    "mfaSectionTitle": "Two-Factor Authentication",
    "accept": "accepted in"
  }
}
</i18n>

<script>
import DataDisplay from '@/components/project/organism/DataDisplay'
import ModalPassword from '@/components/project/globals/ModalPassword.vue'
import ModalChangeDefault from '@/components/project/globals/ModalChangeDefault.vue'
import TfaSection from '@/components/project/tfa/TfaSection.vue'
import { EmdAlert, EmdTabs } from 'emerald-vue/src/components'
import { mapActions, mapGetters } from 'vuex'
import PermissionsWrapper from '@/components/project/ui/PermissionsWrapper.vue'
import formatData from '../../utils/date.utils'

const TABS_TYPES = {
  'version_2': 'permissions_mundi', // v2
  'version_1': 'permissions_pagarme' // v1
}

export default {
  name: 'UsersDetail',
  components: {
    EmdTabs,
    EmdAlert,
    ModalPassword,
    ModalChangeDefault,
    TfaSection,
    PermissionsWrapper,
    DataDisplay
  },
  data () {
    return {
      loadingUser: true,
      loadingList: true,
      permissions: [],
      permissions_pagarme: [],
      showAlertUserDeleted: false,
      merchants: null,
      passwordModalIsOpen: false,
      changeDefaultIsOpen: false,
      merchantSelected: null,
      profileData: [],
      mfaData: [],
      tabs: [
        {
          slotName: TABS_TYPES.version_2,
          enabled: true,
          label: this.$t('permissionMundi'),
        },
        {
          slotName: TABS_TYPES.version_1,
          enabled: true,
          label: this.$t('permissionPagarme'),
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['selectedUser', 'userBackQuery', 'user']),
    userQuery () {
      return this.userBackQuery.query
    },
    userStatus () {
      const isEmployeeRoute = this.$route.name === 'employeeDetail'
      if (!isEmployeeRoute) return null
      
      return this.selectedUser.is_employee
          ? {
              color: 'success',
              text: this.$t('userStatusActive')
            }
          : {
              color: 'danger',
              text: this.$t('userStatusInactive')
            }
    },
    mfaStatus () {
      if (!this.selectedUser?.user_two_factor_authentication?.enabled) return null

      const activeStatus = [
        {
          color: 'info',
          text: this.$t('mfaSms')
        },
        {
          color: 'info',
          text: this.$t('mfaApp')
        },
      ]
      
      return activeStatus[this.selectedUser?.user_two_factor_authentication?.type]
    },
    originRouteName () {
      return this.userBackQuery.name
    },
    userId () {
      return this.$route.params.user_id
    },
    showBtnRemove () {
      return (
        this.$route.name === 'employeeDetail' &&
        this.selectedUser.is_employee &&
        !this.loadingUser &&
        this.permissionIsEnabled('employee:remove') &&
        this.user.email !== this.selectedUser.email
      )
    }
  },
  methods: {
    ...mapActions(['GET_USER_PERMISSIONS', 'GET_PAGARME_USER_PERMISSIONS', 'GET_USER_BY_ID', 'DELETE_EMPLOYEE']),
    getUser () {
      this.$store.commit('setTfaState', false)
      this.GET_USER_BY_ID(this.$route.params.user_id)
        .then(res => {
          this.$store.dispatch('SET_SELECTED_USER', res)
          if (res.user_two_factor_authentication?.enabled) {
            this.$store.commit('setTfaState', true)
          }
          this.loadingUser = false
        })
        .then(() => {
          this.mountProfile()
          this.mountMfa()
        })
    },
    changed ({ slotName }) {
      const actions = {
        permissions_mundi: () => this.fetchPermissionsMundi(),
        permissions_pagarme: () => this.fetchPermissionsPagarme()
      }

      const actionFunction = actions[slotName]
      if (actionFunction) {
        actionFunction()
      }
    },
    fetchPermissionsPagarme () {
      this.loadingList = true
      this.permissions_pagarme = []

      this.GET_PAGARME_USER_PERMISSIONS(this.userId)
        .then(res => {
          res.forEach(permission => this.permissions_pagarme.push(permission))
        })
        .finally(() => {
          this.loadingList = false
        })
    },
    fetchPermissionsMundi () {
      this.loadingList = true
      this.permissions = []

      this.GET_USER_PERMISSIONS(this.userId)
        .then(res => {
          res.forEach(permission => this.permissions.push(permission))
        })
        .finally(() => {
          this.loadingList = false
        })
    },
    urlCompany (merchId) {
      return `${process.env.VUE_APP_COMPANY_URL}/${merchId}`
    },
    openPasswordModal () {
      this.passwordModalIsOpen = true
    },
    closePasswordModal () {
      this.passwordModalIsOpen = false
    },
    deleteUser (password) {
      const body = {
        user_id: this.selectedUser.id,
        body: { password: password }
      }
      return this.DELETE_EMPLOYEE(body).then(() => {
        this.passwordModalIsOpen = false
        this.showAlertUserDeleted = true
        setTimeout(() => {
          this.$router.push({
            name: this.originRouteName,
            query: this.userQuery
          })
        }, 7000)
      })
    },
    openModalChangeDefault (merchant) {
      if (merchant.is_default) return
      this.merchantSelected = merchant
      this.changeDefaultIsOpen = true
    },
    hideChangeDefault (event) {
      if (this.changeDefaultIsOpen) {
        this.changeDefaultIsOpen = false
        if (event.changed) {
          this.permissions = []
          this.fetchPermissionsMundi()
        }
      }
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    },
    mountProfile () {
      const fieldsToMount = {
          id: this.$t('userId'),
          email: this.$t('userEmail'),
          document_number: this.$t('userDocument'),
        }

        if (this.selectedUser.is_employee) fieldsToMount.employee_type = this.$t('userProfile')

        const profile = Object.keys(fieldsToMount).map(el => ({
          fieldName: fieldsToMount[el],
          value: this.selectedUser[el]?.value || this.selectedUser[el],
          copy: true
        }))

      this.profileData = profile
    },
    mountMfa () {
      if (this.selectedUser?.user_two_factor_authentication?.opt_in_whats_app_at) {
        const fieldsToMount = {
        user_two_factor_authentication: this.$t('whatsappFallback'),
      }

      const mfa = Object.keys(fieldsToMount).map(el => ({
        fieldName: fieldsToMount[el],
        value: `${this.$t('accept')} ${formatData(this.selectedUser[el]?.opt_in_whats_app_at)}`,
        copy: false
      }))

      this.mfaData = mfa
      }
    }
  },
  beforeMount () {
    this.getUser()
    this.fetchPermissionsMundi()
  }
}
</script>
