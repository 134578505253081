<template>
  <Modal :open="isOpen">
    <template slot="content">
      <div class="emd-container _footer_end">
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('title') }}
          </h2>
          <button
            class="emd-btn _type_link"
            @click="closeModal"
          >
            <i class="uil uil-times" />
          </button>
        </div>
        <form @submit.prevent="setDefault">
          <emd-alert
            :variant="'danger'"
            v-if="errors.length"
            @cancel="errors = []"
          >
            <template slot="content">
              <p
                v-for="(error, index) in errors"
                :key="index"
              >
              {{ error }}
            </p>
            </template>
          </emd-alert>
          <div class="emd-container__content">
            <div class="emd-container _type_card _content_spaced _footer_end">
              <div class="emd-container__content">
                <div class="el-chunk">
                  <div class="eu-pb_1">
                    <a
                      href="javascript:void(0);"
                      class="emd-text _type_caption _size_section emd-link"
                      >{{ merchant.name }}</a
                    >
                  </div>
                  <div class="emd-copy">
                    <emd-copy :item="merchant.id" />
                  </div>
                </div>
                <div>
                  <p
                    class="emd-text _type_label _color_nonessential _size_tiny"
                  >
                    {{ $t('permission') }}
                  </p>
                  <label class="emd-badge _color_info">{{
                    merchant.type.value
                  }}</label>
                </div>
              </div>
            </div>
            <hr class="emd-divider eu-my_3" />
            <div class="emd-form-group">
              <label class="emd-label">{{ $t('passwordLabel') }}</label>
              <div class="emd-input-group">
                <input
                  class="emd-input"
                  :class="{
                    _state_error: password.hasError,
                    _disabled: loadingDefault
                  }"
                  :type="inputType"
                  v-model="password.value"
                  autocomplete="current-password"
                  :disabled="loadingDefault"
                />
                <a
                  v-on:click="toggleInput()"
                  class="emd-input-icon__link"
                >
                  <i
                    class="uil uil-eye"
                    v-if="inputType === 'password'"
                  />
                  <i
                    class="uil uil-eye-slash"
                    v-if="inputType === 'text'"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="emd-container__footer">
            <button
              type="button"
              class="emd-btn _type_text _color_primary"
              @click="closeModal"
            >
              {{ $t('cancel') }}
            </button>
            <button
              type="submit"
              class="emd-btn _color_primary"
              :class="{ _disabled: !password.value || loadingDefault }"
              :disabled="!password.value || loadingDefault"
            >
              {{ $t('continue') }}
            </button>
          </div>
        </form>
      </div>
    </template>
  </Modal>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Alterar acesso principal",
    "permission": "Permissão",
    "account": "Conta",
    "passwordLabel": "Confirmação de senha",
    "continue": "Continuar",
    "cancel": "Cancelar",
    "errorDefault": "Erro ao alterar acesso principal."
  },
  "en-US": {
    "title": "Change default access",
    "permission": "Permission",
    "account": "Account",
    "passwordLabel": "Password confirmation",
    "continue": "Continue",
    "cancel": "Cancel",
    "errorDefault": "Error changing primary access."
  }
}
</i18n>

<script>
import Modal from '@/components/project/globals/Modal.vue'
import EmdCopy from '@/components/emerald/molecules/EmdCopy.vue'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'

export default {
  name: 'ModalChangeDefault',
  components: {
    Modal,
    EmdCopy,
    EmdAlert
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    merchant: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      inputType: 'password',
      password: {
        value: null,
        hasError: false
      },
      errors: [],
      loadingDefault: false
    }
  },
  methods: {
    closeModal () {
      if (this.isOpen) {
        this.resetForm()
        this.$emit('closeModal', { changed: false })
      }
    },
    toggleInput: function () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    },
    hideSuccess () {
      if (this.isOpen) {
        this.resetForm()
        this.$emit('closeModal', { changed: true })
      }
    },
    resetForm () {
      this.password.value = null
      this.errors = []
    },
    setDefault () {
      this.loadingDefault = true
      this.password.hasError = this.password.value
        ? this.password.value.isEmpty()
        : true
      if (!this.password.hasError) {
        const body = {
          user_id: this.$route.params.user_id,
          params: {
            merchant_id: this.merchant.id,
            change_both: false
          },
          body: {
            password: window.btoa(this.password.value)
          }
        }

        this.$store
          .dispatch('SET_USER_DEFAULT', body)
          .then(() => {
            this.$emit('closeModal', { changed: true })
          })
          .catch(error => {
            this.loadingDefault = false
            if (error && error.body && error.body.notifications) {
              var arrayOfArrays = Object.values(error.body.notifications)
              this.errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
            } else {
              this.errors = [this.$t('errorDefault')]
            }
          })
      }
    }
  }
}
</script>
