import { catchError } from '@/support/errorLogger.js'
import { BackOfficeInstance } from '@/store'

const invite = {
  actions: {
    INVITE_ROOT (context, params = {}) {
      return BackOfficeInstance.invite
        .root(params)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    INVITE_EMPLOYEE (context, params = {}) {
      return BackOfficeInstance.invite
        .employee(params)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    INVITE_SET_AS_EMPLOYEE (context, params = {}) {
      return BackOfficeInstance.invite
        .setAsEmployee(params.query, params.body)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    },
    INVITE_USER (context, params = {}) {
      return BackOfficeInstance.invite
        .create(params)
        .then(response => Promise.resolve(response))
        .catch(error => catchError(error, context))
    }
  }
}

export default invite
