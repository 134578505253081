<template>
  <div
    class="el-core__header"
    :class="{ '_drawer-open': drawerOpen }"
  >
    <div class="el-core__header__left eu-pl_4">
      <div class="emd-logo-custom">
        <div>
          <emd-pagarme-logo width="130" />
        </div>
      </div>
    </div>
    <div class="el-core__header__right">
      <div class="emd-drawer _right">
        <div
          class="emd-drawer__trigger"
          :class="{ _open: drawerOpen }"
        >
          <button
            class="emd-action-button"
            @click="toggleDrawerMenu"
          >
            <i class="uil uil-ellipsis-v emd-action-button__icon" />
          </button>
        </div>
        <div
          class="emd-drawer__content"
          :class="{ _open: drawerOpen }"
        >
          <emd-user-menu
            @userMenuClicked="toggleUserMenu"
            :isOpen="userMenuState"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmdUserMenu from '@/components/emerald/organisms/EmdUserMenu.vue'
import { EmdPagarmeLogo } from 'emerald-vue/src/components'

export default {
  name: 'AppHeader',
  components: {
    EmdUserMenu,
    EmdPagarmeLogo
  },
  props: {
    drawerOpen: {
      type: Boolean
    }
  },
  data () {
    return {
      userMenuState: false
    }
  },
  methods: {
    toggleUserMenu () {
      this.userMenuState = !this.userMenuState
    },
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    }
  }
}
</script>
