import Vue from 'vue'
import Router from 'vue-router'
import Webapp from '@/views/Webapp.vue'
import Merchants from '@/views/Merchant/Merchants.vue'
import Accounts from '@/views/Merchant/Accounts.vue'
import Users from '@/views/User/Users.vue'
import UsersDetail from '@/views/User/UsersDetail.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Webapp,
      beforeEnter: (to, from, next) => {
        // eslint-disable-line
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          store.dispatch('toggleGlobalLoading', true)
          const user = store.getters.user
          const isEmployee = store.getters.isEmployee
          if (user && isEmployee) {
            store.dispatch('toggleGlobalLoading', false)
            next()
          } else if (!user) {
            const params = to.params || {}
            store.dispatch('GET_AUTHORIZATION', params).then(res => {
              if (res.merchant && (res.user.is_root || res.user.is_employee)) {
                next(to)
              } else {
                next({ name: 'unauthorized' })
              }
            })
          } else {
            next({ name: 'unauthorized' })
          }
        }
      },
      children: [
        {
          path: '/',
          redirect: { name: 'merchants' }
        },
        {
          path: '/merchants',
          name: 'merchants',
          component: Merchants
        },
        {
          path: '/merchants/:merchant_id/accounts',
          name: 'accounts',
          component: Accounts,
          beforeEnter: (to, from, next) => {
            // eslint-disable-line
            store.dispatch('SET_ACCOUNT_BACK_QUERY', from.query)
            next()
          }
        },
        {
          path: '/users',
          name: 'users',
          component: Users,
          props: {
            userType: 'CommonsOnly',
            action: 'GET_USERS'
          }
        },
        {
          path: '/users/:user_id',
          name: 'usersDetail',
          component: UsersDetail,
          beforeEnter: (to, from, next) => {
            // eslint-disable-line
            store.dispatch('SET_USER_BACK_QUERY', {
              query: from.query,
              name: 'users'
            })
            next()
          },
        },
        {
          path: '/employee',
          name: 'employee',
          component: Users,
          props: {
            userType: 'RootOnly',
            action: 'GET_USERS_EMPLOYEES'
          }
        },
        {
          path: '/employee/:user_id',
          name: 'employeeDetail',
          component: UsersDetail,
          beforeEnter: (to, from, next) => {
            // eslint-disable-line
            store.dispatch('SET_USER_BACK_QUERY', {
              query: from.query,
              name: 'employee'
            })
            next()
          },
        },
        {
          path: '/root',
          name: 'root',
          redirect: { name: 'employee' }
        },
        {
          path: '/root/:user_id',
          name: 'rootDetail',
          redirect: { name: 'employeeDetail' },
          props: {
            permissionsCall: 'GET_USER_PERMISSIONS'
          }
        }
      ]
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
      beforeEnter: (to, from, next) => {
        // eslint-disable-line
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          store.dispatch('toggleGlobalLoading', true)
          const user = store.getters.user
          const isEmployee = store.getters.isEmployee
          if (user && isEmployee) {
            next({ name: 'merchants' })
          } else if (!user) {
            store.dispatch('GET_AUTHORIZATION', {}).then(res => {
              if (res.user.is_root || res.user.is_employee) {
                next({ name: 'merchants' })
              } else if (
                res.user &&
                !res.user.is_root &&
                !res.user.is_employee
              ) {
                next()
              }
            })
          } else {
            store.dispatch('toggleGlobalLoading', false)
            next()
          }
        }
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        // eslint-disable-line
        if (store.getters.maintenance) {
          store.dispatch('toggleGlobalLoading', false)
          next()
        } else {
          next({ name: 'merchants' })
        }
      }
    },
    {
      path: '*',
      redirect: { name: 'merchants' }
    }
  ]
})

export default router
