const components = {
  apps: {
    shop: ['mundipagg', 'pagarme'],
    hub: ['mundipagg', 'pagarme']
  },
  logo: {
    mundipagg: ['mundipagg'],
    pagarme: ['pagarme'],
    jpmorgan: ['jpmorgan']
  },
  filter: {
    tagInfo: ['mundipagg', 'pagarme'],
    tagPrimary: ['jpmorgan']
  },
  recipients: {
    linkMundipagg: ['mundipagg'],
    linkPagarme: ['pagarme']
  },
  sidemenu: {
    home: ['mundipagg', 'pagarme'],
    trf: ['mundipagg', 'jpmorgan'],
    settings: ['mundipagg', 'pagarme', 'jpmorgan'],
    recurrence: ['mundipagg', 'pagarme'],
    plans: ['mundipagg', 'pagarme'],
    invoices: ['mundipagg', 'pagarme'],
    subscriptions: ['mundipagg', 'pagarme'],
    recipients: ['mundipagg', 'pagarme'],
    webhookConfig: ['mundipagg', 'pagarme']
  },
  charge: {
    attempt: ['mundipagg', 'pagarme'],
    capture: ['mundipagg', 'pagarme'],
    cancel: ['mundipagg', 'pagarme'],
    confirm: ['mundipagg', 'pagarme']
  },
  subscription: {
    cancel: ['mundipagg', 'pagarme']
  },
  roles: {
    shop: ['mundipagg', 'pagarme'],
    readOnly: ['jpmorgan']
  },
  global: {
    onboarding: ['mundipagg', 'pagarme', 'jpmorgan'],
    nps: ['mundipagg', 'pagarme'],
    pix: ['mundipagg', 'pagarme'],
    modalHeader: ['mundipagg']
  },
  globalError: {
    logo: ['mundipagg', 'pagarme']
  },
  onboarding: {
    feedback: ['mundipagg', 'pagarme']
  },
  footer: {
    textJPMorgan: ['jpmorgan'],
    textPagarme: ['pagarme'],
    textMundipagg: ['mundipagg'],
    switchThemeMode: ['mundipagg'],
    status: ['mundipagg', 'pagarme'],
    help: ['mundipagg', 'pagarme'],
    documentation: ['mundipagg', 'pagarme', 'jpmorgan'],
    privacySecurity: ['jpmorgan'],
    urlSite: ['mundipagg', 'pagarme'],
    switchLanguage: ['mundipagg', 'jpmorgan'],
    privacyPolicy: ['mundipagg', 'pagarme'],
    termsOfUse: ['mundipagg', 'pagarme'],
    termsOfUseModal: ['mundipagg'],
    secureEnv: ['mundipagg', 'pagarme', 'jpmorgan']
  }
}

export default components
