export function catchError (error, { commit, dispatch }) {
  if (error.status === 401) {
    dispatch('DELETE_AUTHORIZATION')
    window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
  } else if (error.status !== 400 && error.status !== 402) {
    commit('toggleGlobalLoading', false)
    commit('toggleGlobalError', { status: true, code: error.status })
  }
  return Promise.reject(error)
}
