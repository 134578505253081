<template>
  <div>
    <div
      v-if="tfaIsEnabled"
    >
      <p class="emd-text _color_subtle eu-mt_3">
        {{ $t('description') }}
        <strong>{{ $t('description-part2') }}</strong>
        {{ $t('description-part3') }}
        <strong>{{ $t('description-part4') }}</strong>
        {{ $t('description-part5') }}
      </p>
      <button
        @click="activeDeleteModal"
        class="emd-btn _color_info _type_outline _block_mobile eu-basis_4 eu-basis_4_lg eu-basis_4_xl eu-basis_6_md eu-basis_6_sm eu-basis_12_mobile eu-mt_4 position-relative"
      >
        <span>{{ $t('reset-button') }}</span>
        <i class="uil uil-padlock eu-ml_2 padlock-button"></i>
      </button>
    </div>
    <div
      v-else
      class="eu-pt_2 max-width"
    >
      <emd-alert
        variant="info"
        :title="$t('alert-title')"
        :autoclose="false"
      >
        <template slot="content">
          {{ $t('alert-description') }}
        </template>
      </emd-alert>
    </div>
    <tfa-modal
      :modalState="tfaModalState"
      @update:modalState="tfaModalState = $event"
    />
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Segurança",
    "reset-button": "Redefinir autenticação em dois fatores",
    "description": "Em caso de redefinição, a autenticação em dois fatores deste usuário retornará ao estado de",
    "description-part2": "nunca configurada.",
    "description-part3": "Da próxima vez que o usuário fizer login, será ",
    "description-part4": "obrigatória",
    "description-part5": "a configuração do 2FA novamente.",
    "alert-title": "A autenticação em dois fatores está desativada",
    "alert-description": "Esta conta ainda não fez a configuração da autenticação em dois fatores."
  },
  "en-US": {
    "title": "Security",
    "reset-button": "Reset your two-factor authentication",
    "description": "In case of a reset, this user's two-factor authentication will return to the state of",
    "description-part2": "never configured.",
    "description-part3": "Next time the user logs in, the 2FA setup will be",
    "description-part4": "required",
    "description-part5": "again.",
    "alert-title": "The two-factor authentication is disabled",
    "alert-description": "This account not yet configured the two-factor authentication"
  }
}
</i18n>

<script>
import TfaModal from './TfaModal.vue'
import { EmdAlert } from 'emerald-vue/src/components'

export default {
  name: 'TfaSection',
  components: {
    TfaModal,
    EmdAlert
  },
  computed: {
    tfaIsEnabled () {
      return this.$store.state.user.tfaEnabled
    }
  },
  watch: {
    tfaModalState () {
      this.getTfaState()
    }
  },
  data () {
    return {
      tfaModalState: false,
      error: false
    }
  },
  methods: {
    activeDeleteModal () {
      this.tfaModalState = true
    },
    getTfaState () {
      this.$store
        .dispatch('GET_USER_BY_ID', this.$route.params.user_id)
        .then(res => {
          if (res.user_two_factor_authentication) {
            this.$store.commit('setTfaState', true)
          } else {
            this.$store.commit('setTfaState', false)
          }
        })
    }
  }
}
</script>

<style scoped>
.padlock-button::before {
  position: absolute;
  right: 18px;
  font-size: 16px;
}

.position-relative {
  position: relative;
}

.max-width {
  max-width: 587px;
}
</style>
