<template>
  <div
    class="emd-dropdown _position_right"
    ref="userMenu"
    v-click-outside="clickedOutside"
  >
    <div
      class="emd-dropdown__trigger"
      @click="toggleUserMenu"
      :class="{ _active: isOpen }"
    >
      <span>
        <emd-tippy
          id="profile"
          tag="button"
          class="emd-action-button _has-tooltip"
          :type="'action-button'"
          :content="$t('profile')"
        >
          <i class="uil uil-user emd-action-button__icon"></i>
          <i class="uil uil-angle-down emd-action-button__arrow"></i>
        </emd-tippy>
      </span>
    </div>
    <div
      class="emd-dropdown__content"
      :class="{ _active: isOpen }"
    >
      <div class="emd-dropdown__content__item">
        <span class="emd-badge eu-mt_1">{{ permission }}</span>
        <div class="emd-text _color_default _type_label _size_tiny eu-ma_2">
          {{ $t('profile') }}
        </div>
      </div>
      <ul>
        <li class="emd-dropdown__content__item">
          <a
            class="emd-text _color_default"
            :href="profileUrl"
          >
            <p class="emd-dropdown__content__item__text">
              <span class="emd-text _color_nonessential">
                <i class="uil uil-user"></i>
              </span>
              <span>{{ userName }}</span>
            </p>
          </a>
        </li>
        <li class="emd-dropdown__content__item">
          <a
            class="emd-text _color_danger"
            href="javascript:void(0)"
            @click="triggerLogout()"
          >
            <p class="emd-dropdown__content__item__text">
              <i class="uil uil-sign-out-alt"></i>
              <span>{{ $t('logout') }}</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "logout": "Sair",
    "profile": "Perfil"
  },
  "en-US": {
    "logout": "Logout",
    "profile": "Profile"
  }
}
</i18n>

<script>
import EmdTippy from '@/components/emerald/atoms/EmdTippy'

export default {
  name: 'EmdUserMenu',
  components: {
    EmdTippy
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      profileUrl: `${process.env.VUE_APP_PROFILE_URL}`
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    userName () {
      return `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`
    },
    permission () {
      return this.$store.getters.profile.user.employee_type?.value || 'Employee'
    }
  },
  methods: {
    toggleUserMenu () {
      this.$emit('userMenuClicked')
    },
    clickedOutside (e) {
      if (!this.$refs.userMenu.contains(e.target) && this.isOpen) {
        this.$emit('userMenuClicked')
      }
    },
    triggerLogout () {
      this.$store.dispatch('toggleGlobalLoading', true)
      this.$store.dispatch('DELETE_AUTHORIZATION')
      window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
    }
  }
}
</script>
<style>
.emd-avatar {
  margin-left: auto;
  margin-right: 0;
}
.emd-user-menu__popup-item a svg {
  width: 20px;
}
.emd-user-menu._open .emd-user-menu__popup {
  width: auto;
  height: auto;
  min-height: 0;
}
/* @media (max-width: 767px) {
  .emd-user-menu._open .emd-user-menu__popup {
    min-height: calc(100vh - 76px);
  }
  .emd-user-menu .emd-user-menu__popup {
    right: -18px;
  }
} */
</style>
