const stringExtended = Object.assign(String.prototype, {
  isEmpty () {
    if (this === '' || this === null) return true
    return false
  },
  validEmail () {
    // eslint-disable-next-line
    var re = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return re.test(this)
  }
})

const extendeds = {
  stringExtended
}

export default extendeds
