<template>
  <section class="eu-pb_6">
    <div class="eu-pb_3 eu-display_flex eu-align-items_center">
      <p
        class="emd-text _size_subpage eu-mr_2"
      >
        {{ sectionName }}
      </p>

      <emd-badge
        v-if="status"
        :text="status.text"
        :color="status.color"
        >
      </emd-badge>
    </div>
    <data-display-table
      :tableData="tableData"
    />
    <slot></slot>
  </section>
</template>

<script>
import DataDisplayTable from '@/components/project/molecules/DataDisplayTable'
import { EmdBadge } from 'emerald-vue/src/components'

export default {
  name: 'DataDisplay',
  components: {
    DataDisplayTable,
    EmdBadge
  },
  props: {
    tableData: {
      type: Array,
      Required: true
    },
    sectionName: {
      type: String,
      required: true
    },
    status: {
      type: Object || null,
      required: false
    }
  }
}
</script>