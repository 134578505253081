<template lang="html">
  <emd-modal
    has-header
    :open="modalState"
    @close="closeModal"
  >
    <template v-slot:header>
      <h2 class="emd-text _size_section">
        {{ $t('title') }}
      </h2>
    </template>
    <template v-slot:content>
      <form
        v-if="tfaIsEnabled"
        @submit.prevent="deleteTfa"
        class="el-chunk eu-no-pb"
      >
        <p class="emd-text _color_subtle eu-mt_4">
          {{ $t('description') }}
          <span class="emd-text _type_bold">
            {{ selectedUser.first_name }} {{ selectedUser.last_name }}
            {{ `(${selectedUser.id})` }}?
          </span>
        </p>
        <div
          class="eu-flex eu-justify_space-arround eu-width_100 eu-no-margin eu-pt_5"
        >
          <button
            type="button"
            @click.prevent="closeModal"
            class="emd-btn _color_success _type_outline _block_mobile eu-basis_5 eu-basis_12_mobile eu-order_2_mobile eu-mt_3_mobile"
          >
            <span>{{ $t('cancel-button') }}</span>
          </button>
          <button
            :disabled="isLoading"
            type="submit"
            class="emd-btn _color_success _block_mobile eu-grow_1 eu-ml_1_sm eu-order_1_mobile"
          >
            <emd-loader
              v-if="isLoading"
              type="_subtle"
            ></emd-loader>
            <span v-else>{{ $t('success-button') }}</span>
          </button>
        </div>
      </form>
      <div
        v-else
        class="el-chunk eu-no-pb"
      >
        <p class="emd-text _color_subtle eu-mt_4">
          {{ $t('success-title') }}
          <span
            class="emd-text _type_bold"
            v-if="selectedUser">
            {{ selectedUser.first_name }} {{ selectedUser.last_name }}
            {{ `(${selectedUser.id})` }}.
          </span>
        </p>
        <div
          class="eu-flex eu-justify_space-arround eu-width_100 eu-no-margin eu-pt_5"
        >
          <button
            :disabled="isLoading"
            @click="closeModal"
            class="emd-btn _color_success _block_mobile eu-grow_1 eu-ml_1_sm eu-order_1_mobile"
          >
            <span>{{ $t('success-confirm-button') }}</span>
          </button>
        </div>
      </div>
    </template>
  </emd-modal>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Redefinição da autenticação em dois fatores",
    "description": "Redefinir a autenticação em dois fatores de",
    "cancel-button": "Cancelar",
    "success-button": "Redefinir a autenticação em dois fatores",
    "success-title": "A autenticação em dois fatores foi redefinida para a conta",
    "success-confirm-button": "Ok, entendi"
  },
  "en-US": {
    "title": "Security",
    "reset-button": "Reset your two-factor authentication",
    "description": "Returns the that user two-factor authentication to the never configured state. In the next time that the user does the login, it will be required to configure the 2fa again",
    "alert-title": "The two-factor authentication is disabled",
    "alert-description": "This account not yet configured the two-factor authentication"
  }
}
</i18n>

<script>
import { EmdModal, EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'TfaDeleteModal',
  components: {
    EmdModal,
    EmdLoader
  },
  props: {
    modalState: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedUser () {
      return this.$store.getters.selectedUser
    },
    tfaIsEnabled () {
      return this.$store.state.user.tfaEnabled
    }
  },
  data () {
    return {
      isLoading: false,
      error: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:modalState', false)
    },
    deleteTfa () {
      this.isLoading = true
      this.$store
        .dispatch('DELETE_TFA', this.selectedUser.id)
        .then(r => {
          this.$store.commit('setTfaState', false)
          this.isLoading = false
        })
        .catch(() => {
          this.error = true
          this.isLoading = false
        })
    }
  }
}
</script>
