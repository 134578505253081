<template>
  <div class="emd-container _content_spaced _footer_spaced">
    <div class="emd-container__content">
      <div
        class="eu-display_flex eu-column eu-justify_space-between"
        v-if="isEmpty"
      >
        <div
          class="emd-highlight-tag"
          style="pointer-events: none"
        >
          <span class="emd-tooltip">
            <i class="uil uil-star"></i>
          </span>
        </div>
        <div class="el-chunk eu-pa_3">
          <i
            class="emd-text _size_page _color_subtle uil uil-exclamation-triangle"
          ></i>
          <p class="emd-text _size_section _color_nonessential eu-mt_3">
            {{ $t('noEnterprises') }}
          </p>
        </div>
      </div>
      <div
        class="eu-display_flex eu-column eu-justify_space-between"
        v-else
      >
        <div
          v-if="isMundiScope"
          class="emd-highlight-tag"
          :class="{ _active: permission.is_default }"
        >
          <span
            class="emd-tooltip"
            :data-tooltip="
              permission.is_default ? $t('primaryAccess') : $t('setAsPrimary')
            "
            @click="changeDefault(permission)"
          >
            <i class="uil uil-star"/>
          </span>
        </div>
        <div class="el-chunk">
          <div class="eu-pb_1 eu-pr_1">
            <a
              v-if="isMundiScope"
              :href="urlCompany"
              class="emd-text _type_caption _size_section emd-link"
            >{{ permission.name }}</a
            >
            <p
              v-else
              class="emd-text _type_caption _size_section"
            >
              {{ permission.name }}
            </p>
          </div>
          <div class="emd-copy">
            <emd-copy :item="permission.id"/>
          </div>
        </div>
        <div
          class="el-chunk"
          v-if="permission.account"
        >
          <p class="emd-text _type_label _color_nonessential _size_tiny">
            {{ $t('defaultAccount') }}
          </p>
          <p class="emd-text _color_default">{{ permission.account.name }}</p>
        </div>
      </div>
    </div>

    <div
      class="emd-container__footer"
      v-if="!isEmpty"
    >
      <div class="eu-flex">
        <div
          v-if="isMundiScope"
          class="eu-flex-item eu-basis_0"
        >
          <p class="emd-text _type_label _color_nonessential _size_tiny">
            {{ $t('permission') }}
          </p>
          <p class="emd-badge _color_info">
            {{
              permission.type ? permission.type.value : permission.role.value
            }}
          </p>
        </div>
        <div class="eu-flex">
          <div class="eu-flex-item eu-basis_0">
            <p class="emd-text _type_label _color_nonessential _size_tiny">
              {{ $t('version') }}
            </p>
            <div class="emd-badge _color_info">
              {{ isMundiScope ? '2.0' : '1.0' }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          (permissionIsEnabled('settings.info:edit') ||
            permissionIsEnabled('settings.general_data:edit') ||
            permissionIsEnabled('settings.functionalities:edit')) &&
          isMundiScope
        "
        class="eu-pt_2"
      >
        <emd-button
          type="text"
          color="primary"
          block
          @click="urlCompanyConfiguration(permission.id)"
        >
          <i class="uil uil-external-link-alt"/>
          {{ $t('configCompany') }}
        </emd-button>
      </div>
      <div
        v-else-if="!isMundiScope"
        class="eu-align-self_end"
      >
        <emd-button
          :disabled="isActive"
          color="primary"
          @click="redirect(`${dashAdmin}`)"
        >
          {{ $t('dashAdmin') }}
        </emd-button>

        <emd-button
          color="secondary"
          @click="redirect(`${impersonate}`)"
        >
          {{ $t('impersonate') }}
        </emd-button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "defaultAccount": "Conta padrão",
    "permission": "Permissão",
    "version": "Versão",
    "configCompany": "Configurar no Company",
    "primaryAccess": "Acesso principal",
    "setAsPrimary": "Definir como principal",
    "noEnterprises": "Usuário não possui empresas",
    "dashAdmin": "Acessar Dash",
    "impersonate": "Acessar Impersonate"
  },
  "en-US": {
    "defaultAccount": "Default account",
    "searchUser": "Search User",
    "permission": "Permission",
    "version": "Version",
    "configCompany": "Configure on Company",
    "primaryAccess": "Primary access",
    "setAsPrimary": "Set as primary",
    "noEnterprises": "User have no merchants",
    "dashAdmin": "Access Dash",
    "impersonate": "Access Impersonate"
  }
}
</i18n>

<script>
import EmdCopy from '@/components/emerald/molecules/EmdCopy.vue'
import { EmdButton } from 'emerald-vue/src/components'

export default {
  name: 'PermissionCard',
  props: {
    permission: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isEmpty: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EmdCopy,
    EmdButton
  },
  computed: {
    dashAdmin () {
      return process.env.VUE_APP_DASH_ADMIN_URL
    },
    impersonate () {
      return process.env.VUE_APP_IMPERSONATE_URL
    },
    isMundiScope () {
      return this.permission.scope === 'mundi'
    },
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.permission.id}`
    }
  },
  methods: {
    urlCompanyConfiguration () {
      window.location.assign(`${this.urlCompany}/configuration`)
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    },
    redirect (url) {
      return window.open(url)
    },
    changeDefault (permission) {
      this.$emit('openModalChangeDefault', permission)
    }
  },
}
</script>
