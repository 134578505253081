var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tabbar",staticClass:"emd-tabbar jsTabbar _bordered"},[_c('ul',{staticClass:"emd-tabbar__list"},[_c('li',{staticClass:"emd-tabbar__item"},[_c('router-link',{staticClass:"emd-tabbar__link",class:{
          _active: _vm.$route.name === 'merchants' || _vm.$route.name === 'accounts'
        },attrs:{"to":{ name: 'merchants' },"exact":""}},[_c('i',{staticClass:"uil uil-building eu-mr_2"}),_c('span',{staticClass:"emd-text"},[_vm._v(_vm._s(_vm.$t('merchants')))])])],1),(_vm.permissionIsEnabled('user:search_all'))?_c('li',{staticClass:"emd-tabbar__item"},[_c('router-link',{staticClass:"emd-tabbar__link",class:{
          _active: _vm.$route.name === 'users' || _vm.$route.name === 'usersDetail'
        },attrs:{"to":{ name: 'users' },"exact":""}},[_c('i',{staticClass:"uil uil-user eu-mr_2"}),_c('span',{staticClass:"emd-text"},[_vm._v(_vm._s(_vm.$t('users')))])])],1):_vm._e(),(_vm.permissionIsEnabled('employee:search'))?_c('li',{staticClass:"emd-tabbar__item"},[_c('router-link',{staticClass:"emd-tabbar__link",class:{
          _active:
            _vm.$route.name === 'employee' || _vm.$route.name === 'employeeDetail'
        },attrs:{"to":{ name: 'employee' },"exact":""}},[_c('i',{staticClass:"uil uil-users-alt eu-mr_2"}),_c('span',{staticClass:"emd-text"},[_vm._v(_vm._s(_vm.$t('employee')))])])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }