<template>
  <div
    ref="tabbar"
    class="emd-tabbar jsTabbar _bordered"
  >
    <ul class="emd-tabbar__list">
      <li class="emd-tabbar__item">
        <router-link
          :to="{ name: 'merchants' }"
          exact
          class="emd-tabbar__link"
          v-bind:class="{
            _active: $route.name === 'merchants' || $route.name === 'accounts'
          }"
        >
          <i class="uil uil-building eu-mr_2"/>
          <span class="emd-text">{{ $t('merchants') }}</span>
        </router-link>
      </li>
      <li
        class="emd-tabbar__item"
        v-if="permissionIsEnabled('user:search_all')"
      >
        <router-link
          :to="{ name: 'users' }"
          exact
          class="emd-tabbar__link"
          v-bind:class="{
            _active: $route.name === 'users' || $route.name === 'usersDetail'
          }"
        >
          <i class="uil uil-user eu-mr_2"/>
          <span class="emd-text">{{ $t('users') }}</span>
        </router-link>
      </li>
      <li
        class="emd-tabbar__item"
        v-if="permissionIsEnabled('employee:search')"
      >
        <router-link
          :to="{ name: 'employee' }"
          exact
          class="emd-tabbar__link"
          v-bind:class="{
            _active:
              $route.name === 'employee' || $route.name === 'employeeDetail'
          }"
        >
          <i class="uil uil-users-alt eu-mr_2"/>
          <span class="emd-text">{{ $t('employee') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "merchants": "Empresas",
    "users": "Usuários",
    "employee": "Usuários Employee"
  },
  "en-US": {
    "merchants": "Merchants",
    "users": "Users",
    "employee": "Employee Users"
  }
}
</i18n>

<script>
export default {
  name: 'EmdTabbar',
  methods: {
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  mounted () {
    const $tabbar = this.$refs.tabbar
    let startX
    let scrollLeft
    let isDown = false
    if ($tabbar) {
      $tabbar.addEventListener(
        'mousewheel',
        function (e) {
          e.preventDefault()
          $tabbar.scrollLeft += e.deltaY
        },
        false
      )

      $tabbar.addEventListener(
        'mousedown',
        function (e) {
          isDown = true
          startX = e.pageX - $tabbar.offsetLeft
          scrollLeft = $tabbar.scrollLeft
        },
        false
      )

      $tabbar.addEventListener(
        'mousemove',
        function (e) {
          if (!isDown) return
          const x = e.pageX - $tabbar.offsetLeft
          const walk = (x - startX) * 2
          $tabbar.scrollLeft = scrollLeft - walk
        },
        false
      )

      $tabbar.addEventListener(
        'mouseup',
        function () {
          isDown = false
        },
        false
      )

      $tabbar.addEventListener(
        'touchstart',
        function (e) {
          isDown = true
          if (e.touches.length > 0) {
            startX = e.touches[0].pageX - $tabbar.offsetLeft
          } else {
            startX = e.pageX - $tabbar.offsetLeft
          }
          scrollLeft = $tabbar.scrollLeft
        },
        false
      )

      $tabbar.addEventListener(
        'touchmove',
        function (e) {
          if (!isDown) return
          var x
          if (e.touches.length > 0) {
            x = e.touches[0].pageX - $tabbar.offsetLeft
          } else {
            x = e.pageX - $tabbar.offsetLeft
          }
          const walk = (x - startX) * 2
          $tabbar.scrollLeft = scrollLeft - walk
        },
        false
      )

      $tabbar.addEventListener(
        'touchend',
        function () {
          isDown = false
        },
        false
      )
    }
  }
}
</script>

<style>
.emd-tabbar .emeraldicons {
  height: 16px;
  width: 16px;
  vertical-align: -2px;
}
</style>
