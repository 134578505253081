<template>
  <div class="el-section">
    <modal-password
      v-if="passwordModalIsOpen"
      :callback="deleteUser"
      @cancel="closePasswordModal"
    />
    <modal-invite-admin
      v-if="adminModalIsOpen"
      :isOpen="adminModalIsOpen"
      @closeModal="closeAdminModal"
    />
    <modal-invite-employee
      v-if="employeeModalIsOpen"
      :isOpen="employeeModalIsOpen"
      @closeModal="closeEmployeeModal"
    />
    <div class="el-chunk">
      <label class="emd-label">{{ $t('searchUser') }}:</label>
      <ul class="emd-list _inline _space_small">
        <li>
          <label
            class="emd-radio-button _size_small"
            for="1"
          >
            <input
              id="1"
              v-model="filterUserBy"
              checked
              class="emd-radio-button__input"
              name="radios"
              type="radio"
              value="first_name"
              v-on:change="changeFilter()"
            />
            <div class="emd-radio-button__area"></div>
            <span class="emd-radio-button__text">{{ $t('firstName') }}</span>
          </label>
        </li>
        <li>
          <label
            class="emd-radio-button _size_small"
            for="2"
          >
            <input
              id="2"
              v-model="filterUserBy"
              class="emd-radio-button__input"
              name="radios"
              type="radio"
              value="last_name"
              v-on:change="changeFilter()"
            />
            <div class="emd-radio-button__area"></div>
            <span class="emd-radio-button__text">{{ $t('lastName') }}</span>
          </label>
        </li>
        <li>
          <label
            class="emd-radio-button _size_small"
            for="3"
          >
            <input
              id="3"
              v-model="filterUserBy"
              class="emd-radio-button__input"
              name="radios"
              type="radio"
              value="email"
              v-on:change="changeFilter()"
            />
            <div class="emd-radio-button__area"></div>
            <span class="emd-radio-button__text">{{ $t('email') }}</span>
          </label>
        </li>
      </ul>
      <div class="eu-flex eu-align-items_center">
        <div
          class="eu-flex-item eu-basis_12 eu-basis_7_md eu-mb_2 eu-no-margin_md"
        >
          <div class="emd-input-group _wrapped">
            <input
              v-model="identifier"
              :disabled="loadingList"
              :placeholder="$t('placeholderInput')"
              class="emd-input"
              type="text"
              @keyup="textChanged"
            />
            <button
              :class="{ _color_secondary: identifier !== '' && !loadingList }"
              class="emd-btn"
              @click="searchUser(identifier)"
            >
              <i class="uil uil-search"/>
            </button>
          </div>
        </div>
        <div
          v-if="hasQueryAndItems"
          class="eu-flex-item eu-basis_12 eu-basis_5_md">
          <div class="emd-pagination _simple">
            <span
              v-if="!loadingList"
              class="emd-text _color_default _size_small"
            >{{ users.pagination.current_page }} {{ $t('of') }}
              {{ users.pagination.total_page }}</span
            >
            <div>
              <button
                :disabled="users.pagination.current_page === 1 || loadingList"
                class="emd-btn _color_primary _type_text eu-mr_3"
                @click="goToPage(users.pagination.current_page - 1)"
              >
                {{ $t('previous') }}
              </button>
              <button
                :disabled="
                  users.pagination.current_page ===
                    users.pagination.total_page ||
                  !users.pagination.total_page ||
                  loadingList
                "
                class="emd-btn _color_primary _type_text"
                @click="goToPage(users.pagination.current_page + 1)"
              >
                {{ $t('next') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- grid-list -->
    <emd-alert
      v-if="showAlertUserDeleted"
      variant="success"
      @cancel="showAlertUserDeleted = false"
    >
      <template slot="content">
        <p>Usuário removido com sucesso.</p>
      </template>
    </emd-alert>
    <empty-list v-if="!loadingList && hasQuery && !users.items.length"/>
    <div
      v-if="loadingList"
      class="eu-pa_6 eu-align_center"
    >
      <emd-loader :type="'_invert'"></emd-loader>
    </div>
    <div
      v-else
      class="el-grid-list _auto-fill"
    >
      <div
        v-for="user in users.items"
        :key="user.id"
        class="emd-container _footer_end"
      >
        <div class="emd-container__content">
          <div class="eu-pb_1">
            <h3 class="emd-text _type_caption _size_section eu-text-overflow">
              {{ user.first_name }} {{ user.last_name }}
            </h3>
          </div>
          <div class="emd-text _size_small">
            <div class="emd-copy">
              <emd-copy :item="user.email"/>
            </div>
          </div>
        </div>
        <div class="emd-container__footer">
          <button
            v-if="showBtnRemove(user.email)"
            class="emd-btn _color_danger _type_link"
            @click="openPasswordModal(user.id)"
          >
            <i class="uil uil-trash"/>
            {{ $t('remove') }}
          </button>
          <router-link
            :to="{ name: `${$route.name}Detail`, params: { user_id: user.id } }"
            class="emd-btn _color_primary _type_link"
            tag="button"
            @click.native="gotToUser(user)"
          >
            <i class="uil uil-file-search"/>
            {{ $t('details') }}
          </router-link>
        </div>
      </div>
      <div
        v-if="showCardInvite"
        class="emd-container eu-bg_info"
      >
        <div class="emd-container__content eu-align_center eu-pa_5">
          <div class="el-chunk">
            <p class="emd-text _color_info">{{ $t('invite') }}</p>
          </div>
          <button
            v-if="
              isActionUsers &&
              permissionIsEnabled('merchant_admin.invite:create')
            "
            class="emd-btn _type_link _color_info"
            @click="adminModalIsOpen = true"
          >
            <i class="uil uil-user-plus"/>
            {{ $t('admin') }}
          </button>
          <button
            v-if="
              isActionEmployee &&
              permissionIsEnabled('employee.invite:create')
            "
            class="emd-btn _type_link _color_info"
            @click="employeeModalIsOpen = true"
          >
            <i class="uil uil-users-alt"/>
            {{ $t('mundiUser') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "searchUser": "Buscar usuário",
    "firstName": "Nome",
    "lastName": "Sobrenome",
    "email": "Email",
    "placeholderInput": "Buscar por: Nome / Sobrenome / Email",
    "helperSearch": "Pressione enter ou clique na lupa para buscar",
    "next": "próxima",
    "previous": "anterior",
    "of": "de",
    "remove": "Remover",
    "details": "Detalhes",
    "cancel": "Cancelar",
    "invite": "Convidar novo usuário",
    "admin": "Administrador",
    "mundiUser": "Usuário Employee"
  },
  "en-US": {
    "searchUser": "Search User",
    "firstName": "First Name",
    "lastName": "Last Name",
    "email": "Email",
    "placeholderInput": "Search for: First name / Last name / Email",
    "helperSearch": "Press enter or click the magnifier icon to search",
    "next": "next",
    "previous": "previous",
    "of": "of",
    "remove": "Remove",
    "details": "Details",
    "cancel": "Cancel",
    "invite": "Invite new user",
    "admin": "Admin user",
    "mundiUser": "Employee user"
  }
}
</i18n>

<script>
import EmdLoader from '@/components/emerald/atoms/EmdLoader'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmdCopy from '@/components/emerald/molecules/EmdCopy.vue'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'
import ModalPassword from '@/components/project/globals/ModalPassword.vue'
import ModalInviteAdmin from '@/components/project/globals/ModalInviteAdmin.vue'
import ModalInviteEmployee from '@/components/project/globals/ModalInviteEmployee/Modal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'users',
  components: {
    EmdLoader,
    EmdCopy,
    EmdAlert,
    EmptyList,
    ModalPassword,
    ModalInviteAdmin,
    ModalInviteEmployee
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      users: {
        items: [],
        pagination: {}
      },
      hasQuery: false,
      hasQueryAndItems: false,
      filterUserBy: 'email',
      loadingList: false,
      identifier: '',
      passwordModalIsOpen: false,
      userToDel: null,
      adminModalIsOpen: false,
      showAlertUserDeleted: false,
      employeeModalIsOpen: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    isActionEmployee () {
      return this.$route.name === 'employee'
    },
    isActionUsers () {
      return this.$route.name === 'users'
    },
    showCardInvite () {
      return (
        (this.isActionUsers &&
          this.permissionIsEnabled('merchant_admin.invite:create')) ||
        (this.isActionEmployee &&
          this.permissionIsEnabled('employee.invite:create'))
      )
    }
  },
  methods: {
    ...mapActions(['DELETE_EMPLOYEE', 'SET_SELECTED_USER']),
    gotToUser (user) {
      this.SET_SELECTED_USER(user)
    },
    textChanged (event) {
      if (event.keyCode === 13) {
        this.goToPage(1)
      }
    },
    prepareQuery () {
      const query = this.$route.query || { page: 1 }
      Object.keys(query).forEach(filter => {
        if (['first_name', 'last_name', 'email'].includes(filter)) {
          this.identifier = query[filter]
          this.filterUserBy = filter
        }
      })
      return query
    },
    changeFilter () {
      if (this.identifier) {
        const query = this.$route.query
        query.page = 1
        delete query.first_name
        delete query.last_name
        delete query.email
        query[this.filterUserBy] = this.identifier
        this.getUsers(query)
      }
    },
    showBtnRemove (email) {
      return (
        this.isActionEmployee &&
        this.permissionIsEnabled('employee:remove') &&
        email !== this.user.email
      )
    },
    getUsers (query) {
      this.loadingList = true
      query.page = query.page || 1
      query.size = query.size || 12
      if (this.identifier) {
        query.ignore_removed_claim = true
        query[this.filterUserBy] = this.identifier
        query.internal_only = this.isActionEmployee
      }

      this.$store.dispatch(this.action, query).then(res => {
        this.loadingList = false
        this.users = res
        this.hasQuery = this.identifier && this.filterUserBy
        this.hasQueryAndItems = this.identifier && this.filterUserBy && this.users.items.length
        const queryReplace = { ...query }
        delete queryReplace.size
        this.$router.replace({ query: queryReplace }).catch(err => err)
      })
    },
    goToPage (page) {
      const query = { page: page }
      this.getUsers(query)
    },
    searchUser () {
      const query = { page: 1 }
      this.getUsers(query)
    },
    openPasswordModal (user) {
      this.userToDel = user
      this.passwordModalIsOpen = true
    },
    closePasswordModal () {
      this.userToDel = null
      this.passwordModalIsOpen = false
    },
    deleteUser (password) {
      const body = {
        user_id: this.userToDel,
        body: { password: password }
      }
      return this.DELETE_EMPLOYEE(body).then(() => {
        this.closePasswordModal()
        const query = this.prepareQuery()
        this.getUsers(query)
        this.showAlertUserDeleted = true
      })
    },
    closeAdminModal () {
      this.adminModalIsOpen = false
    },
    closeEmployeeModal () {
      this.employeeModalIsOpen = false
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  beforeMount () {
    const query = this.prepareQuery()
    if (this.identifier && this.filterUserBy) {
      this.getUsers(query)
    }
  }
}
</script>
