<template>
  <div :class="['emd-modal', open ? '_active' : '']">
    <div class="emd-modal__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    open: {
      type: Boolean,
      required: true,
      default: true
    }
  }
}
</script>
<style>
.emd-modal {
  z-index: 10;
}
.emd-modal__content > .emd-container {
  width: 100%;
}
</style>
