<template>
  <div
    class="emd-loader"
    v-bind:class="type"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script>
export default {
  name: 'EmdLoader',
  props: ['type']
}
</script>
