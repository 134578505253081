<template>
  <div class="el-section">
    <modal-create-merchant
      :isOpen="merchantModalIsOpen"
      @closeModal="closeModalMerchant"
    />
    <div class="el-chunk">
      <div class="eu-flex eu-align-items_center">
        <div
          class="eu-flex-item eu-basis_12 eu-basis_7_md eu-mb_3 eu-no-margin_md"
        >
        <emd-form-group
          :state="inputFeedBackStatus"
          :label="$t('searchMerchant')"
          :name="$t('searchMerchant')"
          :feedbacks="[
            {
              msg: $t('maxCharLimit'),
              condition: maxCharLimit
            }
          ]"
          >
              <div class="emd-input-group _wrapped">
                <emd-input
                  type="text"
                  :placeholder="$t('placeholderSearch')"
                  class="emd-input"
                  name="merchantSearch"
                  v-model="identifier"
                  :disabled="loadingList"
                  @keyup="$event => textChanged($event)"
                  :maxLength="65"
                />
                <button
                  @click="searchMerchant(identifier)"
                  class="emd-btn"
                  :class="{ _color_secondary: identifier !== '' && !loadingList }"
                >
                  <i class="uil uil-search" />
                </button>
              </div>
          </emd-form-group>
        </div>
        <div class="eu-flex-item eu-basis_12 eu-basis_5_md">
          <div class="emd-pagination _simple">
            <span
              class="emd-text _color_default _size_small"
              v-if="!loadingList"
              >{{ merchants.pagination.current_page }} {{ $t('of') }}
              {{ merchants.pagination.total_page }}</span
            >
            <div>
              <button
                :disabled="
                  merchants.pagination.current_page === 1 || loadingList
                "
                @click="goToPage(merchants.pagination.current_page - 1)"
                class="emd-btn _color_primary _type_text eu-mr_3"
              >
                {{ $t('previous') }}
              </button>
              <button
                :disabled="
                  merchants.pagination.current_page ===
                    merchants.pagination.total_page || loadingList
                "
                @click="goToPage(merchants.pagination.current_page + 1)"
                class="emd-btn _color_primary _type_text"
              >
                {{ $t('next') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <empty-list v-if="!loadingList && !merchants.items.length" />
    <div
      v-if="loadingList"
      class="eu-pa_6 eu-align_center"
    >
      <emd-loader :type="'_invert'"></emd-loader>
    </div>
    <div
      class="el-grid-list _auto-fill"
      v-else
    >
      <div
        class="emd-container _footer_end"
        v-for="merchant in merchants.items"
        :key="merchant.id"
      >
        <div class="emd-container__content">
          <div class="eu-pb_1">
            <a
              class="emd-link _type_text _size_section"
              :href="urlCompany(merchant.id)"
            >
              {{ merchant.name }}
            </a>
          </div>
          <div class="emd-text _size_small">
            <div class="emd-copy">
              <emd-copy :item="merchant.id" />
            </div>
          </div>
        </div>
        <div class="emd-container__footer">
          <a
            class="emd-btn _type_link eu-mr_3"
            :href="urlCompany(merchant.id)"
          >
            <i class="uil uil-external-link-alt" />
            Company
          </a>
          <router-link
            class="emd-btn _color_primary _type_link"
            @click.native="gotToAccounts(merchant)"
            :to="{ name: 'accounts', params: { merchant_id: merchant.id } }"
          >
            <i class="uil uil-file-search" />
            {{ $t('details') }}
          </router-link>
        </div>
      </div>
      <!-- <div class="emd-container eu-bg_info">
        <div class="emd-container__content eu-align_center eu-pa_5">
          <p class="emd-text _color_info eu-mb_3">{{ $t('createIntegration') }}</p>
          <button class="emd-btn _type_link _color_info" @click="openModalMerchant">
            <i class="uil uil-plus" />
            {{ $t('createNow') }}
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "searchMerchant": "Buscar Empresa",
    "placeholderSearch": "Buscar por: Nome da Empresa / ID / Conta",
    "helperSearch": "Pressione enter ou clique na lupa para buscar",
    "details": "Detalhes",
    "createIntegration": "Criar empresa para integração",
    "createNow": "Criar agora",
    "next": "próxima",
    "previous": "anterior",
    "of": "de",
    "maxCharLimit": "Campo com limite de 65 caracteres"
  },
  "en-US": {
    "searchMerchant": "Search Company",
    "placeholderSearch": "Search for: Company name / ID / Account",
    "helperSearch": "Press enter or click the magnifier icon to search",
    "details": "Details",
    "createIntegration": "Create company for integration",
    "createNow": "Create now",
    "next": "next",
    "previous": "previous",
    "of": "of",
    "maxCharLimit": "Field limited to 65 characters"
  }
}
</i18n>

<script>
import EmdLoader from '@/components/emerald/atoms/EmdLoader'
import EmdCopy from '@/components/emerald/molecules/EmdCopy'
import EmptyList from '@/components/project/globals/EmptyList'
import ModalCreateMerchant from '@/components/project/globals/ModalCreateMerchant'
import { EmdInput, EmdFormGroup } from 'emerald-vue/src/components'

export default {
  name: 'Merchants',
  components: {
    EmdLoader,
    EmdCopy,
    EmptyList,
    ModalCreateMerchant,
    EmdInput,
    EmdFormGroup
  },
  computed: {
    inputFeedBackStatus () {
      return this.maxCharLimit ? 'warning' : null
    }
  },
  data () {
    return {
      merchants: { items: [], pagination: {} },
      loadingList: true,
      identifier: '',
      merchantModalIsOpen: false,
      maxCharLimit: false
    }
  },
  methods: {
    textChanged (event) {
      const maximumLenght = event.target.value.length >= 65

      if (event.keyCode === 13) {
        this.goToPage(1)
      }

      if (maximumLenght) this.maxCharLimit = true
      else this.maxCharLimit = false
    },
    getMerchants (query) {
      this.loadingList = true
      query.page = query.page || 1
      query.size = query.size || 12
      this.$store.dispatch('GET_MERCHANTS', query).then(res => {
        this.loadingList = false
        this.merchants = res
        const queryReplace = { page: query.page }
        if (query.identifier) queryReplace.identifier = query.identifier
        this.$router.replace({ query: queryReplace }).catch(err => err)
      })
    },
    goToPage (page) {
      const query = { page: page }
      if (this.identifier) {
        query.identifier = this.identifier
      }
      this.getMerchants(query)
    },
    searchMerchant (identifier) {
      const query = { identifier: identifier }
      this.getMerchants(query)
    },
    urlCompany (merchId) {
      return `${process.env.VUE_APP_COMPANY_URL}/${merchId}`
    },
    gotToAccounts (merchant) {
      this.$store.dispatch('SET_SELECTED_MERCHANT', merchant)
    },
    openModalMerchant () {
      this.merchantModalIsOpen = true
    },
    closeModalMerchant () {
      this.merchantModalIsOpen = false
    }
  },
  beforeMount () {
    const query = this.$route.query || { page: 1 }
    if (query.identifier) {
      this.$store.dispatch('revertSpecialChar', query.identifier).then(res => {
        this.identifier = res
      })
    } else {
      this.identifier = ''
    }
    this.getMerchants(query)
  }
}
</script>
