<template>
  <div>
    <div
      class="el-centered eu-fade-in"
      v-if="globalLoading && !globalError"
    >
      <emd-pagarme-loader :color="'secondary'" />
    </div>
    <global-error
      v-if="!globalLoading && globalError"
      theme="pagarme"
      :showLogo="true"
      :code="code"
    />
    <template v-else-if="!globalLoading && !globalError">
      <router-view />
    </template>
  </div>
</template>

<script>
import { GlobalError, EmdPagarmeLoader } from 'emerald-vue/src/components'

export default {
  name: 'app',
  components: { GlobalError, EmdPagarmeLoader },
  computed: {
    globalError () {
      return this.$store.state.globalError
    },
    code () {
      return this.$store.state.globalErrorCode
    },
    globalLoading () {
      return this.$store.getters.globalLoading
    },
    pref () {
      return this.$store.getters.pref
    }
  },
  beforeMount: function () {
    const body = document.body
    this.$store.dispatch('GET_PREFERENCES').then(() => {
      const preferences = Object.assign({}, this.pref)
      if (preferences.appearance.mode === 'light') {
        body.classList.remove('_mode_dark')
      } else {
        body.classList.add('_mode_dark')
      }
      this.$store.dispatch('SET_PREFERENCES', preferences)
    })
  }
}
</script>
