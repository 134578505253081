import { catchError } from '@/support/errorLogger.js'
import { BackOfficeInstance } from '@/store'

const merchant = {
  state: {
    selectedMerchant: null
  },
  getters: {
    selectedMerchant (state) {
      return state.selectedMerchant
    }
  },
  mutations: {
    setSelectedMerchant (state, merchant) {
      state.selectedMerchant = merchant
    }
  },
  actions: {
    SET_SELECTED_MERCHANT ({ commit }, merchant) {
      commit('setSelectedMerchant', merchant)
    },
    GET_MERCHANTS (context, params = {}) {
      return BackOfficeInstance.merchant
        .getAll(params)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => catchError(error, context))
    },
    GET_MERCHANT_BY_ID (context, params = {}) {
      return BackOfficeInstance.merchant
        .getById(params)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => catchError(error, context))
    },
    CREATE_MERCHANT (context, params = {}) {
      return BackOfficeInstance.merchant
        .create(params)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => catchError(error, context))
    }
  }
}

export default merchant
