<template>
  <div class="emd-flex-grow_1 emd-flex-shrink_1 emd-flex emd-overflow_hidden">
    <div
      class="emd-container__content emd-flex emd-justify_space-between emd-overflow_hidden"
    >
      <form
        @submit.prevent="checkUserForInviteEmployee"
        id="form-check-user-for-invite"
      >
        <div
          v-if="isLoading"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'"></emd-loader>
        </div>
        <div
          v-else
          class="emd-form-group"
          :class="{ _state_error: fields.email.hasError }"
        >
          <label
            for="invite-email"
            class="emd-label"
            >{{ $t('email') }}</label
          >
          <input
            class="emd-input"
            type="email"
            id="invite-email"
            @input="emailValidation()"
            v-model="fields.email.value"
          />
          <p
            v-for="(error, key) in fields.email.errors"
            :key="key"
            class="emd-input-feedback"
          >
            {{ error }}
          </p>
        </div>
      </form>
    </div>

    <div
      class="emd-container__footer emd-ta_right"
      v-if="!isLoading"
    >
      <button
        class="emd-btn _color_primary _type_text eu-mr_2"
        type="button"
        @click="hideCancelled"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="emd-btn _color_primary"
        form="form-check-user-for-invite"
        type="submit"
        :class="{ _disabled: fields.email.hasError }"
      >
        {{ $t('continue') }}
      </button>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "emailFormatInvalid": "Formato de e-mail inválido.",
    "emailDomainInvalid": "E-mail com domínio inválido."
  },
  "en-US": {
    "emailFormatInvalid": "Invalid email format.",
    "emailDomainInvalid": "Email with invalid domain."
  }
}
</i18n>

<script>
import EmdLoader from '@/components/emerald/atoms/EmdLoader'

export default {
  name: 'FormCheckUser',
  props: ['fields'],
  components: {
    EmdLoader
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    hideCancelled () {
      this.$emit('hideCancelled')
    },
    emailValidation () {
      const errors = []
      const valueMissing = this.fields.email.value
        ? this.fields.email.value.isEmpty()
        : true

      if (valueMissing) {
        errors.push(this.$t('requiredText'))
      } else {
        const isValidEmail = this.fields.email.value.validEmail()

        if (!isValidEmail) {
          errors.push(this.$t('emailFormatInvalid'))
        } else {
          const allowedDomains =
            /\.*@(pagar\.me|stone\.com\.br|mundipagg\.com)/i
          const itsProduction = process.env.NODE_ENV === 'production'

          if (itsProduction && !allowedDomains.test(this.fields.email.value)) {
            errors.push(this.$t('emailDomainInvalid'))
          }
        }
      }

      this.fields.email.hasError = !!errors.length
      this.fields.email.errors = errors
    },
    checkUserForInviteEmployee () {
      this.emailValidation()

      if (this.fields.email.hasError) {
        return
      }

      this.isLoading = true

      this.$store
        .dispatch('GET_USERS', {
          email: this.fields.email.value
        })
        .then(({ items }) => {
          if (items.length === 1) {
            const user = items[0]
            const params = {
              userId: user.id,
              email: user.email,
              firstName: user.first_name,
              lastName: user.last_name
            }
            this.$emit('setDataCheckUser', params)
            this.$emit('setActiveStep', 'inviteSetAsEmployee')
          } else {
            this.$emit('setActiveStep', 'inviteUser')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
