import Vue from 'vue'
import Vuex from 'vuex'
import { catchError } from '@/support/errorLogger.js'
import BackOfficeCore from 'backoffice-js'

import { version } from '@/../package.json'

import account from './modules/account'
import commons from './modules/commons'
import invite from './modules/invite'
import merchant from './modules/merchant'
import roles from './modules/roles'
import user from './modules/user'

Vue.use(Vuex)

export const BackOfficeInstance = new BackOfficeCore({
  environment: process.env.VUE_APP_CORE_ENV,
  type: 'webapp',
  theme: process.env.VUE_APP_THEME
})

const store = new Vuex.Store({
  modules: {
    account,
    commons,
    invite,
    merchant,
    roles,
    user
  },
  state: {
    maintenance: false,
    appVersion: version,
    profile: undefined
  },
  getters: {
    maintenance (state) {
      return state.maintenance
    },
    appVersion (state) {
      return state.appVersion
    },
    profile (state) {
      return state.profile
    },
    isEmployee (state) {
      return state.profile?.user?.is_root || state.profile?.user?.is_employee
    }
  },
  mutations: {
    setProfile (state, profile) {
      state.profile = profile
    }
  },
  actions: {
    GET_AUTHORIZATION (context, params = {}) {
      return BackOfficeInstance.authorization
        .get(params)
        .then(response => {
          context.dispatch('toggleGlobalLoading', false)
          context.commit('setProfile', response)
          context.dispatch('SET_USER', response.user)
          return Promise.resolve(response)
        })
        .catch(error => catchError(error, context))
    },
    DELETE_AUTHORIZATION () {
      window.$cookies.remove(
        `${BackOfficeInstance.envAcronym}mp_tk`,
        '/',
        BackOfficeInstance.domain
      )
      window.$cookies.remove(
        `${BackOfficeInstance.envAcronym}mp_rt`,
        '/',
        BackOfficeInstance.domain
      )
    }
  }
})

export default store
