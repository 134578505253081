<template>
  <div
    class="emd-text _size_small"
    >
    <data-display-field
      v-for="item in tableData"
      :key="item.fieldName"
      :fieldData="item"
      />
  </div>
</template>
<script>
import DataDisplayField from '@/components/project/atoms/DataDisplayField'

export default {
  name: 'DataDisplayTable',
  components: {
    DataDisplayField
  },
  props: {
    tableData: {
      type: Array,
      required: true
    }
  }
}
</script>
<style></style>