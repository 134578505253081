import { catchError } from '@/support/errorLogger.js'
import { BackOfficeInstance } from '@/store'

const account = {
  state: {
    accountBackQuery: {}
  },
  getters: {
    accountBackQuery (state) {
      return state.accountBackQuery
    }
  },
  mutations: {
    setAccountBackQuery (state, value) {
      state.accountBackQuery = value
    }
  },
  actions: {
    GET_ACCOUNTS (context, params = {}) {
      return BackOfficeInstance.account
        .getAll(params.merchantId, params)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => catchError(error, context))
    },
    SET_ACCOUNT_BACK_QUERY ({ commit }, query) {
      commit('setAccountBackQuery', query)
    }
  }
}

export default account
