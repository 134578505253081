<template>
  <div class="emd-copy">
    <strong class="emd-text _color_default">{{ `${fieldData.fieldName}: ` }}</strong>
    <span>{{ fieldData.value }}</span>
    <emd-copy
      v-if="fieldData.copy"
      :item="fieldData.value"
      :showLabel="false"
      :textLeft="true"
      />
  </div>
</template>

<script>
import EmdCopy from '@/components/emerald/molecules/EmdCopy.vue'

export default {
  name: 'DataDisplayField',
  components: {
    EmdCopy
  },
  props: {
    fieldData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .emd-copy {
    gap: 4px;
  }
</style>